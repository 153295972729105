import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, TableCell, TextField } from '@mui/material';
import SearchIcon from '../../../icons/SearchIcon.svg';
import { AppDispatch, RootState } from '../../../store';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { getTrades, IFilter, TradeStatusEnum } from '../../../store/otcSlice';
import { PagingTable } from '../../../components/PagingTable';
import useAppSelector from '../../../hooks/useAppSelector';
import moment from 'moment';
import { generatePath, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { StyledTableCell, StyledTableRow } from '../Admin.styled';

const Trades = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const dispatch: AppDispatch = useAppDispatch();
  const trades = useAppSelector((state: RootState) => state.otc.trades);
  const totalTrades = useAppSelector(
    (state: RootState) => state.otc.totalTrades
  );
  const history = useHistory();
  const setSearchQueryDebounced = useDebouncedCallback(
    (value) => setSearchQuery(value),
    1500
  );
  const ITEMS_PER_PAGE = 20;

  const onChangePage = useCallback((page: number) => {
    let filters: IFilter[] = [
      {
        field: 'status',
        operator: '=',
        value: TradeStatusEnum.DISPUTE_OPEN.toString()
      }
    ];

    if (searchQuery) {
      filters.push({
        field: 'id',
        operator: 'ilike',
        value: searchQuery,
      });
    }

    dispatch(
      getTrades({
        take: ITEMS_PER_PAGE,
        page,
        orders: [
          {
            field: 'createdAt',
            direction: 'desc',
          },
        ],
        filters,
      })
    );
  }, [searchQuery]);

  useEffect(() => {
    onChangePage(1);
  }, []);

  useEffect(() => {
    if (searchQuery) {
      onChangePage(1);
    }
  }, [searchQuery]);

  return (
    <Box p="40px">
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQueryDebounced(event.target.value);
          }}
          placeholder="Поиск"
          InputProps={{
            endAdornment: (
              <>
                <img src={SearchIcon} />
              </>
            ),
          }}
          variant="standard"
        />
      </Box>

      <Box>
        <Box fontWeight="bold" pb="10px">
          Требует решения
        </Box>
        <PagingTable
          heads={['Сделка', 'Валюта', 'Сумма', 'Дата/Время']}
          items={trades}
          onRow={(item) => (
            <StyledTableRow
              onClick={() =>
                history.push(
                  generatePath('/admin/disputes/:id', {
                    id: item.id,
                  })
                )
              }
            >
              <StyledTableCell sx={{ maxWidth: '200px', fontWeight: 'bold' }}>
                {item.id}
              </StyledTableCell>
              <StyledTableCell> {item.advertisement.marketId}</StyledTableCell>
              <StyledTableCell>{item.volume}</StyledTableCell>
              <StyledTableCell>
                {moment(new Date(item.createdAt)).format('DD.MM.YYYY / HH:mm')}
              </StyledTableCell>
            </StyledTableRow>
          )}
          onHeadRowCell={(head) => <TableCell>{head}</TableCell>}
          total={totalTrades}
          itemsPerPage={ITEMS_PER_PAGE}
          loading={false}
          onChangePage={(page: number) => onChangePage(page)}
        />
      </Box>
    </Box>
  );
};

export { Trades };
