import React from 'react';
import styles from './style.module.scss';
import { Header, Section, Paragraph } from '../styledComponents';

const Refund = () => {
  return <>
    <Header>1. Policy background</Header>
    <Section>
      <Paragraph>
        GoldenBit UAB (hereinafter — we, the Company).The company has therefore created this refund policy in order to prevent disagreement with our customers and inform them that Company does not provide any refunds on usual condition due to the nature of services. At the same time, the Company guarantees to any its customer his right for refund, if any the paid services were not provided. Every refund in such cases is treated by the Company with reasonable care.
        The company also draw customers’ attention that is reasonable and justified circumstances and if necessary, the Company may refund payments received from customers through any available payment system, including bank cards.
      </Paragraph>
    </Section>
    
    <Header>2. Chargeback notice</Header>
      <Section>
        <Paragraph>
          The company expects the customer to contact it using Company contact details to resolve any problem or issue related to his any previously approved transaction before the customer makes any chargeback request with the issuing bank or credit card company. This section does not affect any rights and/or claims, which the customer may have against the bank, credit card company or any financial institution or payment provider.
          Any attempt to requesting chargeback without contacting the Company may be considered as a breach of Terms and Conditions and customer may be held liable in a competent court of applicable law. Company will investigate any chargeback requests made by the customers and in response will inform the customer’s issuing bank whether any service or transaction has been canceled.
          If we, however, receive a chargeback for any transaction we reserve the rights to hold chargeback amount from the customer’s current balance for the period of investigation and the dispute cycle of the payment system and credit the funds back after completing this process and charging all applicable fees.
        </Paragraph>
    </Section>
    
    <Header>3. General conditions</Header>
      <Section>
        <Paragraph>
        In accordance with the Company’s policy, a refund to the customer is allowed in the following cases:
        <ul>
          <li>when funds were not credited to the account balance after completion of transaction processing due to technical reasons or as a result of an error; or</li>
          <li>funds had been deposited into account balance, but no one operations or orders were executed and no funds were used for the services.</li>
        </ul>
          In such cases, if the request for a return is approved by the company, the funds will be refunded back through the same payment method and to the same credit/debit card, bank account or another wallet from which the funds were originally received.
          In order to apply for a refund, the customer must complete the respective refund request and send it to the Golden Bit support address: @golden_bit_support
        The request must contain the following details:
        <ul>
          <li>Transaction ID</li>
          <li>Customer name and Email</li>
          <li>Amount and currency of requested refund</li>
          <li>Reason and description of the request.</li>
        </ul>
          If a customer does not provide the necessary information in the request, including the reason and justification for the refund, or if the customer has already used deposited funds for trading or other services, Company has the right to decline such refund request without further consideration.
          To prevent prohibited and unlaw conduct, all payments, and information related to refund may be additionally verified by the Company. In such case, The Company may request the customer to provide certain documents, including, but not limited to, identification documents, copy of the customer’s invoice or/and any other proof of the fact that disputed payment was made. In case if the customer fails to provide appropriate documents or information within three (3) days upon the Company request or in case of any doubts as to the authenticity of provided documents, the Company shall be entitled to decline the user’s refund request.
          The refund will be done for the full amount unless other arrangements and fees have been made in accordance with the Terms and Conditions.
        </Paragraph>
    </Section>
    
    <Header>4. Processing timeline</Header>
      <Section>
        <Paragraph>
          Within three (3) business days as of the date of refund request receipt, Company shall contact the customer to request further information (if required) and the customer shall provide such information as soon as reasonably practicable but in any case no later than five (5) days following such request.
          Company shall notify the customer by email on its final decision regarding a refund within five (5) business days following the receipt of the last requested additional document or information or within three (3) business days following the receipt and reviewing of a refund request if no further information or document is required.
          Refund will be processed without undue delay, and in any event within five (5) days beginning with the day on which the Company agrees that the customer is entitled to a refund. The lead time of processing of refund transaction can take 3-5 business days, excluding funds transfer time from the bank or payment provider.
        </Paragraph>
    </Section>
    
    <Header>5. Confines and policy changes</Header>
      <Section>
        <Paragraph>
          This refund policy concerns exclusively customer’s deposited/added funds, and it does not refer to any refunds for send or exchange transactions that were processed as Company’s services.
          Refunds in excess of the original transaction amount are prohibited for any payment method.
          If there is just cause to suspect that the origins of customer’s funds and/or customer’s entire activities are contrary to Company’s Anti-Money Laundering Policy (AML Policy), Company reserve unilateral rights to deny deposit funds into account balance within Company’s systems, or withdrawal to customer account and send to other payment system and also hold such assets and refund them back to the sender’s account. Please be notified that we are obliged to inform the respective government institutions concerning each AML Policy conflict and disclose customer’s personal data.
          This refund policy will be amended from time to time if there is any change in the legislation. Terms of this refund policy are subject to change by the Company and in the case of any amendments, Company will make an appropriate announcement before the changes will apply.
          In case if the customer does not wish to accept the revised refund policy, he should not continue to use the Company’s services. If the customer continues to use the services after the change comes into effect, the use of the services will be bound by the new refund policy.
        </Paragraph>
      </Section>
    </>
};

export default Refund;
