/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import useAppSelector from '../../../hooks/useAppSelector';
import SearchIcon from '../../../icons/SearchIcon.svg';
import {
  getTransactionList, selectMyTransactionsCount,
  selectTransactionList,
  setDirection,
  setMyTransactionsCount,
  setTransaction,
  setTransactionList,
  Transaction,
} from '../../../store/walletSlice';
import { Trans } from '../Trans';
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  buttonUnstyledClasses,
  tabUnstyledClasses,
  styled,
} from '@mui/material';
import { PagingTable } from '../../../components/PagingTable';
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow,
} from '../Admin.styled';

const Transactions = () => {
  const history = useHistory();
  const [tab, setTab] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const transactions = useAppSelector(selectTransactionList);
  const totalTransactions = useAppSelector(selectMyTransactionsCount);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const directionRef = useRef<'in' | 'out'>('in');

  const direction = useAppSelector((state) => state.wallet.direction);

  const uncompletedTransactions = useMemo(
    () =>
      transactions
        .filter(
          (el) =>
            ['new', 'confirm:wait'].includes(el.status) &&
            el.direction === direction
        )
        .filter((item) =>
          searchQuery ? item.txId.includes(searchQuery) : item
        )
        .sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA > dateB ? -1 : 1;
        }),
    [transactions, searchQuery, direction]
  );
  const completedTransactions = useMemo(
    () =>
      transactions
        .filter(
          (el) =>
            ['error', 'complete', 'reject', 'confirm'].includes(el.status) &&
            el.direction === direction
        )
        .filter((item) =>
          searchQuery ? item.txId.includes(searchQuery) : item
        )
        .sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA > dateB ? -1 : 1;
        }),
    [transactions, searchQuery, direction]
  );

  const onRowClick = useCallback((item: Transaction, index: number) => {
    
    dispatch(setTransaction(null));
    dispatch(setMyTransactionsCount(0));
    dispatch(setTransactionList([]));
    dispatch(setDirection('in'));
    setTab(0);

    directionRef.current = direction

    history.push(
      generatePath('/admin/transactions/:id', {
        id: item.id,
      })
    );
  }, [direction]);

  useEffect(() => {
    const newDirection = tab === 0 ? 'in' : 'out';
    dispatch(setDirection(newDirection));
  }, [tab]);

  useEffect(() => {
    console.log(`effect!!`);

    history.listen(async (location, action) => {
      if (location.pathname.match(/^\/admin\/transactions$/i) !== null) {
        console.log(`reload transactions list`);

        console.log(`prev direction: ${directionRef.current}`);

        setDirection(directionRef.current);
        setTab(directionRef.current === 'in' ? 0 : 1);

        setLoading(true);
        await dispatch(
          getTransactionList({
            direction: directionRef.current,
            page: 1,
            take: 9999,
          })
        );
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log(`new direction: ${direction}`);

    setLoading(true);
    async function fetchTransactionList() {
      await dispatch(
        getTransactionList({
          direction,
          page: 1,
          take: 9999,
        })
      );
    }
    fetchTransactionList();
    setLoading(false);
  }, [direction]);

  return (
    <Switch>
      <Route exact path="/admin/transactions">
        <Box p="40px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            lineHeight="56px"
          >
            <TabsUnstyled
              value={tab}
              onChange={(
                event: React.SyntheticEvent,
                newValue: string | number
              ) => {
                !loading && setTab(Number(newValue));
              }}
            >
              <StyledTabsList>
                <StyledTab>Ввод</StyledTab>
                <StyledTab>Вывод</StyledTab>
              </StyledTabsList>
            </TabsUnstyled>
            <TextField
              placeholder="Поиск"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchQuery(event.target.value)
              }
              variant="standard"
            />
          </Box>

          <Box height="100%">
            <Box fontWeight="bold" my="25px">
              {`Требует подтверждения (${
                loading ? 0 : uncompletedTransactions.length
              })`}
            </Box>

            <PagingTable
              heads={['TxID', 'Валюта', 'Сумма', 'Контрагент', 'Дата / Время']}
              items={uncompletedTransactions}
              total={totalTransactions}
              itemsPerPage={10}
              onRow={(item, index: number) => (
                <StyledTableRow
                  key={index}
                  onClick={() => onRowClick(item, index)}
                >
                  <StyledTableCell
                    sx={{ maxWidth: '250px', fontWeight: 'bold' }}
                  >
                    {item.txId}
                  </StyledTableCell>
                  <StyledTableCell>{item.currencyId}</StyledTableCell>
                  <StyledTableCell>{item.amount}</StyledTableCell>
                  <StyledTableCell>{item.userName}</StyledTableCell>
                  <StyledTableCell>
                    {moment(item.createdAt).format('D.M.YYYY HH:mm:ss')}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              loading={loading}
              onHeadRowCell={(item) => (
                <StyledTableHeadCell>{item}</StyledTableHeadCell>
              )}
            />

            <Box fontWeight="bold" my="25px">
              {`Подтвержденные (${loading ? 0 : completedTransactions.length})`}
            </Box>

            <PagingTable
              heads={['TxID', 'Валюта', 'Сумма', 'Контрагент', 'Дата / Время']}
              items={completedTransactions}
              itemsPerPage={9999}
              onRow={(item, index: number) => (
                <StyledTableRow
                  key={index}
                  onClick={() => onRowClick(item, index)}
                >
                  <StyledTableCell
                    sx={{ maxWidth: '250px', fontWeight: 'bold' }}
                  >
                    {item.txId}
                  </StyledTableCell>
                  <StyledTableCell>{item.currencyId}</StyledTableCell>
                  <StyledTableCell>{item.amount}</StyledTableCell>
                  <StyledTableCell>{item.userName}</StyledTableCell>
                  <StyledTableCell>
                    {moment(item.createdAt).format('D.M.YYYY HH:mm:ss')}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              loading={loading}
              onHeadRowCell={(item) => (
                <StyledTableHeadCell>{item}</StyledTableHeadCell>
              )}
            />
          </Box>
        </Box>
      </Route>
      <Route exact path="/admin/transactions/:id" component={Trans} />
    </Switch>
  );
};

const StyledTab = styled(TabUnstyled)`
  background-color: #f5f5f5;
  color: #000000;
  border: 2px solid transparent;
  padding: 10px 15px;
  min-width: 100px;
  cursor: pointer;
  border-radius: 36px;
  font-size: 16px;
  &.${tabUnstyledClasses.selected} {
    background-color: #ffffff;
    border-color: #cba977;
    color: #cba977;
  }
`;

const StyledTabsList = styled(TabsListUnstyled)`
  display: flex;
  gap: 10px;
`;

export default Transactions;
