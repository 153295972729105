import { Box, Button, styled } from '@mui/material';
import { createRef, useEffect } from 'react';
import { ChatMessageType } from '../../store/chatSlice';
import EmptyAvatarIcon from '../../icons/EmptyAvatarIconGold.svg';
import DownloadIcon from '../../icons/DownloadIcon.svg';
import { TChatAction } from '../Chat';
import moment from 'moment';

interface ChatMessagesProps<T> {
  messages: T[];
  userId: string;
  actions?: TChatAction[];
  onActionClick?: (index: number) => void;
  fullHeight?: boolean;
}

const ChatMessages = <T extends ChatMessageType>({
  messages,
  userId,
  actions,
  onActionClick,
  fullHeight = false,
}: ChatMessagesProps<T>) => {
  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (messages && messages.length && containerRef.current) {
      // scroll to bottom
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  const checkIsServiceUser = (userId: string): boolean =>
    userId === '00000000-0000-0000-0000-000000000000';

  return (
    <Box
      position="relative"
      style={{
        height: 'calc(100% - 100px)',
      }}
      display="flex"
      flexDirection="column"
    >
      <MessagesContainer
        style={{
          flex: fullHeight ? '1' : '',
        }}
        ref={containerRef}
      >
        {messages.map((message: T) => (
          <MessageContainer
            sx={{
              padding: {
                md: '5px 15px',
                lg: '10px 30px',
              },
            }}
          >
            <Box
              key={message.id}
              position="relative"
              display="flex"
              alignItems="center"
              gap="10px"
              mb="25px"
              flexDirection={userId === message.userId ? 'row-reverse' : 'row'}
            >
              <Box display="flex" alignSelf="flex-end">
                <img
                  src={EmptyAvatarIcon}
                  alt=""
                  style={{
                    width: '36px',
                    marginBottom: '5px'
                  }}
                />
              </Box>
              <Box flexDirection="column">
                <Box flexDirection="column" gap="10px">
                  {message.message.length ? (
                    <Box
                      style={{
                        borderRadius: userId === message.userId ? '14px 14px 0px 14px' : '14px 14px 14px 0px',
                        backgroundColor:
                            checkIsServiceUser(message.userId)
                                ? '#FFE3B9'
                                : '#f5f5f5',
                        wordBreak: "break-word"
                      }}
                      sx={{
                        lineHeight: {
                          sm: '16px',
                          md: '18px',
                          lg: '20px',
                        },
                      }}
                      p="10px 20px"
                    >
                      {!checkIsServiceUser(message.userId) && (
                        <Box
                          fontSize="13px"
                          color="#999999"
                          fontWeight="500"
                          sx={{
                            textAlign:
                              userId === message.userId ? 'right' : 'left',
                          }}
                        >
                          {message.userName}
                        </Box>
                      )}
                      <Box
                          style={{
                            color: '#000000'
                          }}
                          dangerouslySetInnerHTML={{
                            __html: message.message
                                .replace(/<|>|'|"/g, '')
                                .replace(/\n/g, '<br />'),
                          }}
                      />
                    </Box>
                  ) : null}

                  {message.fileIds && message.fileIds.length ? (
                      <Box
                          display="flex"
                          flexDirection={
                            userId === message.userId ? 'row-reverse' : 'row'
                          }
                          gap="10px"
                          mt="5px"
                      >
                        <Box>
                          {message.fileIds.map((id, index) => (
                              <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="10px"
                              >
                                <img src={DownloadIcon} alt="" />
                                <a
                                    download
                                    href={
                                      process.env.REACT_APP_HTTP_API_URL +
                                      'api/v1/file/' +
                                      id
                                    }
                                    target={'_blank'}
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: 'none',
                                      color: '#696969',
                                    }}
                                >
                                  {id}
                                </a>
                              </Box>
                          ))}
                        </Box>
                      </Box>
                  ) : null}

                  <Box
                    color="#696969"
                    fontSize="12px"
                    sx={{mt: '10px'}}
                    textAlign={userId === message.userId ? 'right' : 'left' }>
                    {moment(message.createAt).format('HH:mm')}
                  </Box>
                </Box>
              </Box>
            </Box>


          </MessageContainer>
        ))}
      </MessagesContainer>

      {actions && actions.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          style={{
            backgroundColor: '#ffffff',
          }}
          p="10px"
        >
          {actions.map((action, index) => (
            <Button
              variant="outlined"
              onClick={(_) => onActionClick && onActionClick(index)}
            >
              {action.label}
            </Button>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

const MessageContainer = styled(Box)`
  background-color: #ffffff;
  transition: 100ms ease all;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const MessagesContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflowY: 'auto',
  height: '530px',
  [theme.breakpoints.down('md')]: {
    height: '335px',
  },
  "&::-webkit-scrollbar": {
    marginLeft: "30px",
    width: "5px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#8b9296",
    borderRadius: "100px"
  }
}));

export { ChatMessages };
