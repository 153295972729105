import { Box, styled } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import usePopup from '../../hooks/usePopup';
import { AppDispatch } from '../../store';
import {
  getTradeTimerCancelDuration,
  setTradeTimerCancelDuration,
  TradeChatStatusEnum,
  TradeStatusEnum,
  TradeTimerCancelDurationType,
} from '../../store/otcSlice';
import { AlertPopup } from '../AlertPopup';
import * as workerTimers from 'worker-timers';

interface ChatTimerProps {
  timer: TradeTimerCancelDurationType | null;
  updateTradeStatus: () => void;
}

const ChatTimer = ({ timer, updateTradeStatus }: ChatTimerProps) => {
  const [timerValue, setTimerValue] = useState<number>(-1);
  const { setPopup } = usePopup();
  const dispatch: AppDispatch = useAppDispatch();

  const tradeStatus = useAppSelector((state) => state.otc.tradeStatus);

  useEffect(() => {
    console.log(tradeStatus);
    if (tradeStatus === TradeStatusEnum.CANCEL && timer) {
      resetTimer();
      setPopupWarning();
    }

    if (tradeStatus === TradeStatusEnum.DISPUTE_OPEN && timer) resetTimer();
    if (tradeStatus === TradeStatusEnum.COMPLETE && timer) resetTimer();
  }, [tradeStatus]);

  useEffect(() => {
    //когда store установил новый timer
    if (timer) {
      if (timer.durationSecondsRemain <= 0) {
        // если он истек
        updateTradeStatus();
        setPopupWarning();
        resetTimer();
        return;
      }

      if (!timer) {
        // если в каком то месте таймер обнулили = null
        setTimerValue(-1); // сбрасываем местный таймер
        return;
      }

      setTimerValue(timer.durationSecondsRemain); // если не истек записываем в местный таймер
      const intervalId = workerTimers.setInterval(() => {
        setTimerValue((prev: number) => prev - 1);
      }, 999);

      return () => workerTimers.clearInterval(intervalId);
    }
  }, [timer]);

  useEffect(() => {
    if (
      tradeStatus !== TradeStatusEnum.CANCEL && // если статус сделки в течении моего таймера
      tradeStatus !== TradeStatusEnum.DISPUTE_OPEN
    ) {
      // не менялся вручную
      if (timer && timerValue === 0) {
        // и мой тамер подошел к 0
        dispatch(getTradeTimerCancelDuration({ id: timer.tradeId }));
      }
    }
  }, [timerValue]);

  const resetTimer = () => {
    setTimerValue(-1);
    dispatch(setTradeTimerCancelDuration(null));
  };

  const setPopupWarning = () => {
    if (timer) {
      if (
        isTradeChatStatus([
          TradeChatStatusEnum.OPEN_LIMIT,
          TradeChatStatusEnum.PAYMENT_LIMIT,
        ])
      ) {
        setPopup(
          <AlertPopup
            closeable={false}
            onClose={() => {}}
            positiveButton="Ок"
            onPositiveButtonClick={() => {
              setPopup(null);
            }}
          >
            <Box>Сделка отменена!</Box>
          </AlertPopup>
        );
      }
    }
  };

  const isTradeChatStatus = (statuses: string[]) => {
    if (timer) {
      return statuses.map((s) => s.toString()).includes(timer.type);
    }
  };

  return (
    <>
      {timer && timerValue > 0 ? (
        <Timer>
          {isTradeChatStatus([TradeChatStatusEnum.OPEN_LIMIT]) &&
            'Автотомена сделки произойдёт через'}
          {/* {isTradeChatStatus([TradeChatStatusEnum.PAYMENT_LIMIT]) && 'Автотомена сделки произойдёт через'} */}
          {isTradeChatStatus([TradeChatStatusEnum.CONFIRMATION_LIMIT]) &&
            'Спор будет открыт через'}
          <b>
            {' '}
            {moment
              .utc(moment.duration(timerValue, 'seconds').as('milliseconds'))
              .format('mm:ss')}
          </b>
        </Timer>
      ) : null}
    </>
  );
};

export default ChatTimer;

const Timer = styled(Box)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.6)',
  height: '45px',
  lineHeight: '45px',
  fontSize: '18px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    lineHeight: '25px',
    fontSize: '14px',
  },
  color: '#ffffff',
  textAlign: 'center',
  marginBottom: '10px',
}));
