import React, { createRef, useCallback, useEffect, useState } from 'react';
import DealArrowRightIcon from '../../icons/DealArrowRightIcon.svg';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  AdvertisementChatType,
  getAdvertisementChatList,
} from '../../store/otcSlice';
import useAppSelector from '../../hooks/useAppSelector';
import {
  addMessage,
  getChatMessages,
  selectMessages,
  setMessages,
} from '../../store/chatSlice';
import { selectUser } from '../../store/userSlice';
import { generatePath, useHistory } from 'react-router';
import socketManager from '../../services/socketManager';
import { uploadFileService } from '../../services/api/file';
import { useAuth } from '../../hooks/useAuth';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { Chat } from '../../components/Chat';
import createChat from '../../lib/createChat';
import { Sidebar } from './Sidebar';
import EmptyAvatarIcon from '../../icons/EmptyAvatarIcon.svg';
import VerifiedIcon from '../../icons/VerifiedIconGold.svg';
import BackIcon from '../../icons/BackArrowIcon.svg';
import { useMediaQuery } from '@mui/material';

const Messages = () => {
  const { token } = useAuth();
  const chats = useAppSelector((state) => state.otc.advertisementChatList);
  const [roomId, setRoomId] = useState<string | undefined>();
  const [connected, setConnected] = useState<boolean>(false);
  const messages = useAppSelector(selectMessages);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const chatContainerRef = createRef<HTMLDivElement>();
  const history = useHistory();
  const [attachments, setAttachments] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery('(min-width: 768px)');
  const [isUnreadMessagesInChat, setIsUnreadMessagesInChat] =
    useState<boolean>(false);
  const [readMessagesChatsIds, setReadMessagesChatsIds] = useState<
    Array<string>
  >([]);
  console.log(matches);
  useEffect(() => {
    dispatch(getAdvertisementChatList({ page: 1, take: 10 }));
    const interval = setInterval(() => {
      dispatch(getAdvertisementChatList({ page: 1, take: 10 }));
    }, 10000);
    return () => {
      setReadMessagesChatsIds([]);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    dispatch(setMessages([]));
    setConnected(false);
    socketManager.socket?.close();
    socketManager.socket = null;
    if (roomId && token) {
      console.log(`connect to chat`);
      socketManager.socket = createChat(roomId, token);
      socketManager.socket.on(`message-to-${roomId}`, (message) => {
        dispatch(addMessage(message));
        getAdvertisementChatList({
          page: 1,
          take: 10,
        });
      });
      socketManager.socket.on(`connected-to-${roomId}`, (users) => {
        console.log(`connected ${roomId}`);
        setConnected(true);

        dispatch(
          getChatMessages({
            id: roomId,
            currentUserId: user?.id,
            isReadAllMessages: isUnreadMessagesInChat, // нужно ли читать соо
          })
        );
      });
    }

    if (roomId) {
      //чтобы установить у открытого чата unreadMessagesCount в 0
      setReadMessagesChatsIds((readMessagesChatsIds) => [
        ...readMessagesChatsIds,
        roomId,
      ]);
    }
  }, [roomId]);

  useEffect(() => {
    if (messages && messages.length && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const onFileUpload = useCallback(
    (
      files: {
        file: File;
        valid: boolean;
      }[]
    ) => {
      if (files.length && !loading) {
        setLoading(true);

        files
          .filter((file) => file.valid)
          .forEach(async (file) => {
            const data = new FormData();
            data.append('file', file.file);
            try {
              const response = await uploadFileService.uploadFile(data);

              console.log(response.data.id);

              setAttachments([response.data.id, ...attachments]);
            } catch (err) {}
          });

        setLoading(false);
      }
    },
    [loading]
  );

  const onDeleteAttachment = useCallback(
    (id: string) => {
      setAttachments(attachments.filter((attachId) => attachId !== id));
    },
    [attachments]
  );

  const onSubmit = useCallback(
    (message: string) => {
      if (connected && roomId) {
        if (attachments.length) {
          socketManager.socket?.emit('message-to-server', {
            message,
            roomId,
            type: 'file',
            jwtToken: token,
            fileIds: attachments,
          });

          setAttachments([]);
        } else {
          socketManager.socket?.emit('message-to-server', {
            message,
            roomId,
            type: 'text',
            jwtToken: token,
          });
        }
      }
    },
    [connected, roomId, attachments]
  );

  const room = chats.find((chat) => chat.room.id === roomId);

  const checkUnreadMessagesInChat = (chat: AdvertisementChatType) => {
    console.log(chat.room.countUnreadedMessages > 0);
    if (chat.room.countUnreadedMessages > 0) {
      setIsUnreadMessagesInChat(true);
    } else {
      if (isUnreadMessagesInChat) setIsUnreadMessagesInChat(false);
    }
  };

  return (
    <>
      {chats.length ? (
        <Box
          display="flex"
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row',
            },
          m: {
              md: '-24px',
          }
          }}
        >
          {matches ? (
            <Sidebar
              chats={chats}
              onClick={(chat: AdvertisementChatType) => {
                checkUnreadMessagesInChat(chat);
                setRoomId(roomId !== chat.room.id ? chat.room.id : undefined);
              }}
              roomId={roomId}
              userId={user?.id}
              readMessagesChatsIds={readMessagesChatsIds}
            />
          ) : (
            !roomId && (
              <Sidebar
                chats={chats}
                onClick={(chat: AdvertisementChatType) => {
                  checkUnreadMessagesInChat(chat);
                  setRoomId(roomId !== chat.room.id ? chat.room.id : undefined);
                }}
                roomId={roomId}
                userId={user?.id}
                readMessagesChatsIds={readMessagesChatsIds}
              />
            )
          )}

          <Box flex="1" position="relative">
            {roomId ? (
              <>
                {room &&
                  room.users.map((item) => (
                    <>
                      {item.userId !== user?.id && (
                        <Box
                          style={{
                            borderBottom: '1px solid #f0f0f0',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '6px 6px 6px 12px',
                            cursor: 'pointer',
                          }}
                          sx={{
                            display:{
                              sm: "flex",
                              md: "none"
                            }
                          }}
                        >
                          <Box onClick={() => setRoomId('')}>
                            <img src={BackIcon} alt="" />
                          </Box>
                          <img src={EmptyAvatarIcon} alt="" />
                          <Box>
                            <Box>
                              <Box fontWeight="bold" fontSize="12px" color="#464646">
                                {item ? item.name : 'deleted'}
                              </Box>

                              {item && item.userIsVerified && (
                                <img src={VerifiedIcon} alt="" />
                              )}
                            </Box>
                            <Box mt="5px" fontSize="10px" color="#999999">
                              {room.room.id}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                {room?.tradeId && (
                  <Box
                    display="flex"
                    color="#666666"
                    style={{
                      cursor: 'pointer',
                    }}
                    sx={{
                      padding:{
                        sm: "10px",
                        md: "20px",
                      },
                      gap:{
                        sm: "10px",
                        md: "20px"
                      },
                      fontSize:{
                        sm: "12px",
                        md: "14px"
                      }
                    }}
                    onClick={() =>
                      history.push(
                        generatePath('/trade/:id', {
                          id: room.tradeId,
                        })
                      )
                    }
                  >
                    Активная сделка #{room?.tradeIdNumber}
                    <img src={DealArrowRightIcon} alt="" />
                  </Box>
                )}

                <ChatContainer>
                  <Chat
                    messages={messages}
                    userId={user?.id!!}
                    onSubmit={onSubmit}
                    inputPlaceholder="Введите сообщение"
                    onFileUpload={onFileUpload}
                    onDeleteAttachment={onDeleteAttachment}
                    attachments={attachments}
                  />
                </ChatContainer>
              </>
            ) : (
              matches && (
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h5" color="#999999">
                    Выберите чат
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          p="40px"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const ChatContainer = styled(Box)(({theme})=>({
  flex: "1",
  borderLeft: "1px solid #dce5e9",

  [theme.breakpoints.down("md")]:{
    borderLeft: "none"
  }
}));

export default Messages;
