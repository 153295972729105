import news1 from '../../../assets/images/news1.png';
import news2 from '../../../assets/images/news2.png';
import news3 from '../../../assets/images/news3.png';

export interface IPopularItem {
  id?: number;
  label?: string;
  content?: string;
  date?: string;
  commentCount?: string;
  timeToRead?: string;
  img?: string;
}

export default [
  {
    id: 1,
    label: 'Потеснить золото биткоин пока не в силах',
    content:
      'Несмотря на рост числа держателей BTC, крупнейшей криптовалюте все еще не удается вытеснить золото с первого места в рейтинге самых безопасных активов. Такой точки придерживается CEOзолотодобывающей компании Evolution Mining Джейк Клейн.  В интервью CNBC он заявил, что биткоин — крайне волатильный... ',
    date: '21 августа 2021',
    commentCount: '5 комментариев',
    timeToRead: '10 минут на прочтение',
    img: news1,
  },
  {
    id: 2,
    label: 'Гонки по вертикали: как ADA стала самой успешной монетой недели',
    content:
      'За минувшие семь дней наилучшую результативность в ТОП-10 криптовалют показала Cardano (ADA). Ее стоимость взлетела примерно на 30%. За сутки цена ADA прибавила еще 17% и достигла $2,52 — рекордного значения в истории монеты.  Cardano сумел выбить Binance Coin (BNB)...',
    date: '21 августа 2021',
    commentCount: '5 комментариев',
    timeToRead: '10 минут на прочтение',
    img: news2,
  },
  {
    id: 3,
    label:
      'Что новый день готовит: перспективы и прогнозы ведущих криптовалют',
    content:
      'После забега первой половины августа крупнейшие цифровые валюты — BTC и ETH — вновь вышли на тропу отступления, оправдав ожидания многих аналитиков. Коррекция вернулась до того, как BTC смог достичь психологической отметки в $50 000...',
    date: '21 августа 2021',
    commentCount: '5 комментариев',
    timeToRead: '10 минут на прочтение',
    img: news3,
  },
];