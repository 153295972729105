import React from 'react';
import { Box } from '@material-ui/core';
import { Header, Section, Paragraph } from '../styledComponents';

const UsersAgreement = () => {
  return (
    <Section>
      <Header>1. PREAMBLE</Header>
    <Section>
      <Paragraph>
        <Paragraph>1.1. This document (hereinafter - the Rules) contains the main provisions regarding the operation and operation of the GoldenBit.</Paragraph>
        <Paragraph>1.2. The Rules determine the principles and conditions under which the Administrator - the company GoldenBit UAB - provides the Client (the person accepting the Rules) access to the System Services, and the Client, in turn, undertakes to use the System and its Services in accordance with
          the Administrator’s requirements established in the Rules.</Paragraph>
        <Paragraph>1.3. The Rules are the official public offer of the Administrator, addressed to legal persons
          (including individuals who have reached the age of majority), on the provision of the opportunity to use the System and its Services.</Paragraph>
        <Paragraph>1.4. The moments that are not reflected in the Rules, the Administrator can regulate with his internal procedures and documents, providing information about them to a specific Client upon his written justified request.</Paragraph>
      </Paragraph>
    </Section>
    
    <Header>2. TERMS AND DEFINITIONS</Header>
      <Section>
        <Paragraph>
          <Paragraph>2.1. Authorization data - a combination of Login and Password.</Paragraph>
          <Paragraph>2.2. Authorization - the process of authenticating the Client by Login and Password.</Paragraph>
          <Paragraph>2.3. Prohibited activity - an activity that has one or more of the following qualities: it is
            criminally punishable in the country of jurisdiction of the Client; referred to in Appendix 1 to the Rules; contrary to universal human ethics and moral standards.</Paragraph>
          <Paragraph>2.4. Identification - the procedure for providing the Administrator with the Client personal data established by law or the Rules.</Paragraph>
          <Paragraph>2.5. Identified Customer - a registered Customer whose identity is established by passing through the Identification procedure.</Paragraph>
          <Paragraph>2.6. Client - a person who has the right or obligation to transfer, receive, use, acquire Funds in accordance with the Rules.</Paragraph>
          <Paragraph>2.7. Wallet - an account of a Client registered in the System and using the Services, stored in
            the System database, owned by this Client, reflecting the balance of the Funds belonging to him.
          </Paragraph>
          <Paragraph>2.8. Unregistered Client - A client who has not passed the Registration procedure: may use Funds with restrictions, in accordance with the Rules.</Paragraph>
          <Paragraph>2.9. Operation - any transfer of Funds using the System.</Paragraph>
          <Paragraph>2.10. Payment - Funds recorded on the Wallet and transferred from the Client to a third party or from a third party to the Client.</Paragraph>
          <Paragraph>2.11. Registration - the result of entering the person’s data into the System, after which the Client is identified by the System.</Paragraph>
          <Paragraph>2.12. Details - the following addresses and phone numbers: from the Administrator, for
            mail correspondence: GoldenBit UAB, for communication by e-mail: GoldenBit UAB, from the Client - contact details, notified by the Client electronically during the registration procedure.
          </Paragraph>
          <Paragraph>2.13. System - a set of hardware and software designed to provide the System Services to the Client by the Administrator.</Paragraph>
          <Paragraph>2.14. Funds - funds in electronic form that belong to the Client and are recorded on his Wallet.</Paragraph>
          <Paragraph>2.15. Parties - the Administrator and the Client at the joint mention.</Paragraph>
          <Paragraph>2.16. Services (also System Services) - Operations with Funds carried out using the System on Clients' Wallets.</Paragraph>
          <Paragraph>2.17. Chargeback (English chargeback) - a procedure for canceling a payment by bank card initiated by the cardholder through his bank.</Paragraph>
        </Paragraph>
    </Section>
    
    <Header>3. ORDER OF ACCESSION TO THE RULES (ACCEPTANCE OF THE RULES)</Header>
      <Section>
      <Paragraph>
        <Paragraph>3.1. Acceptance by the Client of the Rules (acceptance of a public offer) is carried out by
          successively performing the following actions by the Client: 1) filling out registration forms on the Site, including the generation of Authorization data; 2) familiarization with the terms of the Rules; 3) affixing a note of consent with them in the appropriate field of the registration form.
        </Paragraph>
        <Paragraph>3.2. Acceptance of the terms of the Rules means the complete and unconditional acceptance by
          the Client of all the terms of the Rules without any exceptions and / or restrictions and is equivalent to the conclusion of a bilateral written agreement.
        </Paragraph>
        <Paragraph>3.3. The term for acceptance of the terms of the Rules is not limited.</Paragraph>
        <Paragraph>3.4. Obtaining access to the Services by the Client is carried out exclusively subject to the preliminary acceptance of the Rules by him.</Paragraph>
      </Paragraph>
    </Section>
    
    <Header>4. GoldenBit SYSTEM</Header>
      <Section>
        <Paragraph>
          <Paragraph>4.1. After Registration in the System, the Client opens a Wallet. The Wallet stores electronic funds; it allows you to send and receive electronic payments. The wallet is denominated in the currency chosen by the Client from the available currencies.</Paragraph>
          <Paragraph>4.2. The period of storage of the Funds in the Wallet is not limited, interest on them is not charged.</Paragraph>
          <Paragraph>4.3. With respect to the Wallet, limits on replenishment, payments and withdrawals of Funds may
            be applied, depending on the status of the Client, such as the Wallet and other factors considered by the Administrator at his sole discretion.
          </Paragraph>
          <Paragraph>4.4. The Administrator provides the Client with the opportunity to use non-personalized Wallets or personalized Wallets.</Paragraph>
          <Paragraph>4.5. To use the Services, the Client must open the Wallet by going through the Registration
            procedure on the Site, confirming his agreement with the Rules, as well as his legal capacity. Customer - an individual to use the Services must be an adult.
          </Paragraph>
          <Paragraph>4.6. A client may open a Wallet if, from a legal point of view, this is legal in the country of its jurisdiction.</Paragraph>
          <Paragraph>4.7. Information about the Client’s Operations with Funds and their use of the Wallet is recorded and stored by the Administrator during the term of the Rules and the next five years.</Paragraph>
          <Paragraph>4.8. The Client’s actions that change the balance of the Funds confirm his agreement with the amount of the balance reflected in his Wallet at the previous moment.</Paragraph>
          <Paragraph>4.9. Access to the Wallet and execution of any Operations using the Wallet are possible only after the Client Authorization.</Paragraph>
          <Paragraph>4.10. Login is the Customer’s email address or an alphanumeric combination specified by the Customer during Registration.</Paragraph>
          <Paragraph>4.11.Passwords and Payment passwords are created by the Client independently and can be changed at any time.</Paragraph>
          <Paragraph>4.12. The Client bears all responsibility for keeping his Authorization data confidential. Any actions with the Wallet made using the correct Authorization data are recognized as committed by the Client.</Paragraph>
          <Paragraph>4.13. In case of loss of Authorization data by the Client, the Administrator provides the Client with the opportunity to restore access to the Wallet.</Paragraph>
          <Paragraph>4.14. The Administrator can lock the Wallet (suspend Payments from the Wallet of this Client), in some cases, according to the Rules and requirements of the law.</Paragraph>
          <Paragraph>4.15. A client can close his Wallet by contacting Support.</Paragraph>
        </Paragraph>
    </Section>
    
    <Header>5. TRANSACTIONS WITH MEANS</Header>
      <Section>
        <Paragraph>
          <Paragraph>5.1. The Client can replenish his Wallet by going to the Personal Account on the Site and following the relevant instructions for replenishing the Wallet.</Paragraph>
          <Paragraph>5.2. Wallet replenishment methods are payment services provided by third parties, therefore they are not part of the System Services.</Paragraph>
          <Paragraph>5.3. The administrator has the right to limit the list of methods for replenishing Wallets for certain categories of Clients.</Paragraph>
          <Paragraph>5.4. When replenishing the Wallet with third parties, all rights and obligations regarding the
            Funds credited to the Wallet arise with the Client. The actions of third parties are evaluated by the Parties as committed by these parties in the interests of the Client.
          </Paragraph>
          <Paragraph>5.5. For security reasons, limits are set for replenishment.</Paragraph>
          <Paragraph>5.6. Charges may be applied for replenishment of the Wallet.</Paragraph>
          <Paragraph>5.7. Transfer of Funds is carried out on the basis of the Client’s order made electronically using the Wallet.</Paragraph>
          <Paragraph>5.8. Upon receipt of an order to transfer funds from the Client, the Administrator checks the Client’s right to dispose of the Funds on the basis of the Client’s Authentication using Authorization Data.</Paragraph>
          <Paragraph>5.9. The Administrator has the right to limit the list of recipients of Funds for certain categories of Customers. Specific restrictions are indicated on the Site.</Paragraph>
          <Paragraph>5.10. All Transactions carried out by the Client using the System are final and are not subject to
            dispute, review or cancellation, with the exception of Wallet replenishment operations involving external systems classified as fraudulent.
          </Paragraph>
        </Paragraph>
      </Section>
    
    <Header>6. REPORTING</Header>
      <Section>
        <Paragraph>
          <Paragraph>6.1. The Client, at its discretion, independently generates reports for any period in his Personal Account in System.</Paragraph>
          <Paragraph>6.2. The client must make sure that the information on his System is accurate and up to date.</Paragraph>
        </Paragraph>
      </Section>

    <Header>7. PERSONAL DATA</Header>
      <Section>
        <Paragraph>
          <Paragraph>7.1. The Administrator has the right to collect, store and process personal data entered by
            Clients during the Transaction, as well as other data automatically transferred to the Administrator in the process of using the System, transferring this data to third parties in order to comply with the Rules or additional agreements with them, as well as all other permitted by applicable law, Operations with personal data of Clients.
          </Paragraph>
          <Paragraph>7.2. The administrator has the right to collect, store and process information about purchases, paid services and works for targeted advertising and for statistical purposes, as well as transmit statistical information to third parties without disclosing the personal data of Clients.</Paragraph>
        </Paragraph>
      </Section>

    <Header>8. RIGHTS AND OBLIGATIONS OF THE PARTIES</Header>
      <Section>
        <Paragraph>
          <Paragraph>8.1. The client has the right:</Paragraph>
          <Paragraph>8.1.1. gain access to all System Services in accordance with their status and established restrictions;</Paragraph>
          <Paragraph>8.1.2. open the Wallet in the GoldenBit System,</Paragraph>
          <Paragraph>8.1.3. receive technical and informational support.</Paragraph>
          <Paragraph>8.2. The administrator has the right:</Paragraph>
          <Paragraph>8.2.1. expand the list of Services;</Paragraph>
          <Paragraph>8.2.2. modify software, interfaces, procedures, Services;</Paragraph>
          <Paragraph>8.2.3. in case of malfunctions, for preventive purposes and to prevent unauthorized access to the System, suspend its operation;</Paragraph>
          <Paragraph>8.2.4. request from the Client identifying documents and other information in cases stipulated by the Rules;</Paragraph>
          <Paragraph>8.2.5. in the cases established by the Rules, refuse to perform Operations (including, but not limited to, when the Client attempts to carry out Prohibited Activities);</Paragraph>
          <Paragraph>8.2.6. take action against customers in case of violation of the Rules;</Paragraph>
          <Paragraph>8.2.7. charge the Customer fees for their services according to the Rates;</Paragraph>
          <Paragraph>8.3. The client is obliged:</Paragraph>
          <Paragraph>8.3.1. comply with the terms of the Rules with the Administrator.</Paragraph>
          <Paragraph>8.3.2. provide during registration in the System and the passage of the Identification procedure reliable data.</Paragraph>
          <Paragraph>8.3.3. do not provide third parties with the possibility of using their Wallet, do not disclose their Authorization data;</Paragraph>
          <Paragraph>8.3.4. upon detection of an Operation conducted without his consent, unauthorized access to his personal data, loss of Authorization data - notify the Administrator immediately; All Transactions performed using the Password (Payment Password) before such a message are considered to be completed by the Client;</Paragraph>
          <Paragraph>8.3.5. ensure that there is no malware on his computer and any storage medium used by him; work with the System on the computer on which you have installed: an antivirus, with an updated database
            (no later than the same date that you log into the System), a firewall, as well as an updated version of the browser, all necessary updates for the operating system, as well as software security; use only licensed equipment;
          </Paragraph>
          <Paragraph>8.3.6. Do not refuse to cooperate in the investigation or provide evidence of identity or any information upon request;</Paragraph>
          <Paragraph>8.3.7. not to use the System to perform Operations aimed at illegally making a profit or concealing tax income;</Paragraph>
          <Paragraph>8.3.8. Do not use the System for illegal purposes, including not conducting Prohibited Activities;</Paragraph>
          <Paragraph>8.3.9. independently take into account the income received as a result of Transactions in the System and pay taxes in accordance with the legislation of your country;</Paragraph>
          <Paragraph>8.3.10. not allow the presence of a negative balance of funds in the wallet.</Paragraph>
          <Paragraph>8.3.11. The client guarantees that all the conditions of the Rules are clear to him, and he accepts them unconditionally and in full.</Paragraph>
          <Paragraph>8.3.12. The Client is fully responsible for any canceled, invalid, challenged Transactions and for any Charges.</Paragraph>
          <Paragraph>8.3.13. Failure to comply with any of the conditions of this section 10.3 blames the Customer for all losses incurred.</Paragraph>
          <Paragraph>8.4. The administrator is obliged:</Paragraph>
          <Paragraph>8.4.1. Define the Rules, organize and exercise control over their compliance with the Clients;</Paragraph>
          <Paragraph>8.4.2. provide Clients with access to the System;</Paragraph>
          <Paragraph>8.4.3. send the Client notifications of any Transactions made from his Wallet to the email address specified by the Client;</Paragraph>
          <Paragraph>8.4.4. provide support service.</Paragraph>
        </Paragraph>
      </Section>

    <Header>9. RESPONSIBILITY OF THE PARTIES</Header>
      <Section>
        <Paragraph>
          <Paragraph>9.1. In the event of failure to fulfill and (or) improper performance of their obligations under the Rules, the Parties shall be liable in accordance with the Rules and the legislation of Lithuania.</Paragraph>
          <Paragraph>9.2. For the delay in the fulfillment by the Client of any obligation expressed in monetary form and provided for by the Rules, the Client shall pay a fine to the Administrator in the amount of 0.1% (zero point one tenth) of the amount of the unfulfilled obligation for each day of delay. The Client is obliged to pay the specified penalty to the Administrator within 3 (three) business days from the date of receipt by the Client of the corresponding requirement of the Administrator. Filing a fine is a right, but not an obligation of the Administrator.</Paragraph>
          <Paragraph>9.3. The Client agrees to indemnify the Administrator for the damage caused by any payments collected from the Administrator due to violations of the law by the Client.</Paragraph>
          <Paragraph>9.4. The Client is fully responsible for all Transactions recorded in his Wallet, including any Transactions using bank payment cards. Such Transactions include any transactions concluded by employees, managers, subcontractors and (or) consultants, authorized persons, agents, as well as affiliates of the Client or other people who have gained access to his Wallet.</Paragraph>
          <Paragraph>9.5. The customer is solely responsible for compliance with applicable laws.</Paragraph>
          <Paragraph>9.6. The Client guarantees that his activity on the Internet and in the System does not contradict generally recognized principles and moral standards.</Paragraph>
          <Paragraph>9.7. The amounts of the Client's obligations can be debited from his Wallet by the Administrator
            without acceptance. If there are insufficient Funds in the Client’s Wallet, the Administrator may demand
            that the Client immediately replenish his Wallet with the missing amount or begin the process of collecting debt from the Client.
          </Paragraph>
          <Paragraph>9.8. The Client agrees not to take any actions that mislead other persons regarding the
            services provided by the Administrator and which can directly or indirectly damage the Administrator’s business reputation, including impersonating the Administrator’s representatives in any quality and context, falsifying the System’s internal information, including transaction numbers, wallets and any other data, in the form of text, screenshots and any other media, the creation of clone sites based on the System and other similar actions. In case of violation of this rule, the Administrator reserves the right to block accounts, freeze funds until the end of the proceedings, as well as other types of restrictions on the provision of services. The administrator also reserves the right to initiate the prosecution of violators in courts of relevant jurisdiction, including cases when such actions are committed by unauthorized persons who are not Clients.
          </Paragraph>
        </Paragraph>
      </Section>

    <Header>10. DISCLAIMER (STATEMENT OF LIMITATION OF LIABILITY)</Header>
      <Section>
        <Paragraph>
          <Paragraph>10.1. The Administrator is not responsible to the Client in the event of:</Paragraph>
          <Paragraph>10.1.1. transfer by the Client of his data to third parties, deliberate granting access to his Wallet in the System or other violation of data confidentiality by the Client;</Paragraph>
          <Paragraph>10.1.2. the presence of “viruses” and other malware in the equipment and software used by the Client to access the System;</Paragraph>
          <Paragraph>10.1.3. unlawful actions of third parties, including those related to the use of the Customer Registration, as well as his e-mail;</Paragraph>
          <Paragraph>10.1.4. any disputes regarding transactions for the sale and purchase or other transactions concluded between Clients using the System;</Paragraph>
          <Paragraph>10.1.5. claims of tax, regulatory and law enforcement authorities against the Client regarding reporting or taxation of his transactions using the System.</Paragraph>
          <Paragraph>10.2. The Administrator is not responsible for the temporary inoperability of the System,
            malfunctions and errors in the operation of hardware or software (including, but not limited to, disconnection or damage to the power supply and communication networks, software failures, interruptions in the work of mail, Internet providers, payment systems, other lines, channels and (or) networks that are provided, served or served by third parties, etc.) that occurred through no fault of the Administrator, and in this case does not bear responsibility for possible losses of the Client.
          </Paragraph>
          <Paragraph>10.3. The administrator is not responsible for:</Paragraph>
          <Paragraph>10.3.1. the Client’s lack of access to software or hardware that ensures the functioning of the System, and is not liable for any losses incurred by the Client.</Paragraph>
          <Paragraph>
            10.3.2. the consequences of the inability to contact the Client with the details provided to them, as well as the provision by the Client of inaccurate information to the System or their non-updating;
          </Paragraph>
          <Paragraph>10.3.3. Client losses resulting from the impossibility of his identification;</Paragraph>
          <Paragraph>10.3.4. any payment services that are supplied to the Client by third parties;</Paragraph>
          <Paragraph>10.3.5. Client losses resulting from his failure to become familiar with the current version of the Rules.</Paragraph>
          <Paragraph>10.4.In the event that the Authorization data is lost,the Wallet is blocked by the System,the Client lost the Password, the Wallet is blocked or other events whose consequences cannot be eliminated without identifying the Client, the Administrator is not liable to the Unidentified Client for possible losses and the inability to use the Wallet in the future. Confirmation of the ownership of the Wallet to an unidentified
            Client is possible by providing the original of the Client’s contract with the Mobile Communications Administrator.
          </Paragraph>
          <Paragraph>10.5. The administrator is not responsible for losses of the Client and third parties resulting from:</Paragraph>
          <Paragraph>10.5.1. Wallet Lock</Paragraph>
          <Paragraph>10.5.2.the impossibility of making Transactions with the Wallet,regardless of the grounds for such impossibility;</Paragraph>
          <Paragraph>10.5.3. incorrect filling in of instructions on the execution of the Operation;</Paragraph>
          <Paragraph>10.5.4. issuing erroneous instructions for crediting and (or) debiting funds from the Wallet;</Paragraph>
          <Paragraph>10.5.5. violation by the Client of the established procedure for making payments;</Paragraph>
          <Paragraph>10.5.6. compliance by any Party with legislative or regulatory requirements;</Paragraph>
          <Paragraph>10.5.7. violation by the Client of the requirements of these Rules and (or) the terms of any
            contract concluded by the Client in connection with the use of the System, as well as the requirements of the Administrator, and (or) generally recognized principles of morality.
          </Paragraph>
          <Paragraph>10.6. In any case, the Administrator bears responsibility to the Client within an amount not exceeding the amount of the Charges charged from this Client for the previous 3-month period.</Paragraph>
          <Paragraph>10.7. The Administrator shall not be liable for any indirect or indirect losses of the Client or third parties, including for lost profits, loss (loss, non-receipt, unreasonable waste) of income, profits, contracts, customers, time, data, company or reputation.</Paragraph>
          <Paragraph>
            10.8. The Client agrees to protect the interests of the Administrator, reimburse the Administrator
            for losses and pay the Administrator compensation, as well as exempt the Administrator and his affiliates from damage from any claims, claims, expenses or expenses (including legal costs, fines or forfeits) that they incur as a result of or based on a violation by the Client of these Rules, any applicable laws or regulations and (or) use of the System. This provision shall remain in force after the termination of the relationship between the Parties.
          </Paragraph>
          <Paragraph>
            10.9. If, through the fault of the Customer, the Payment made is the reason for the payer to submit
            a claim to the Administrator regarding the protection of the violated right, the Administrator has the right to demand from the recipient of the Means of compensation for losses incurred as a result of the recovery of funds or other property from the Administrator in favor of the payer.
          </Paragraph>
          <Paragraph>10.10. The administrator provides the Services for the use of Clients in the form in which they currently exist, and without any guarantees.</Paragraph>
          <Paragraph>10.11. Collaboration with nominal banks (shell banks) is prohibited and is not carried out. A
            nominee bank is defined as a bank registered in any jurisdiction in which it is not physically present and which is not affiliated with any financial institution subject to the relevant legislation.
          </Paragraph>
        </Paragraph>
      </Section>

      <Header>11. INFORMATION INTERACTION</Header>
      <Section>
        <Paragraph>
          <Paragraph>11.1. When registering, the Client is obliged to provide the Administrator with reliable information (Details) for communication with the Client and sending him notifications.</Paragraph>
          <Paragraph>11.2. By default, the appropriate way to inform the Client about a Transaction is to send a notification to the email address provided by the Client.</Paragraph>
          <Paragraph>11.3. The client can contact the Administrator at any time by sending a message or by calling (during the hours of its operation) the Support Service.</Paragraph>
          <Paragraph>11.4. The administrator can inform the Client about important changes in the operation of the System and new features of the System by sending notifications by e-mail, as well as by posting news messages in the client area of the Site.</Paragraph>
        </Paragraph>
      </Section>
    
      <Header>12. SECURITY</Header>
        <Section>
          <Paragraph>
            <Paragraph>12.1. The Client must take all reasonable measures to save the Password and Payment Password for his Wallet in a safe place and not disclose it to anyone.</Paragraph>
            <Paragraph>12.2. The Client is fully responsible for all risks associated with the use of the Internet when interacting with the Administrator (other Clients, third parties).</Paragraph>
            <Paragraph>12.3. The Client assumes full responsibility for the safety of his Authorization data and all the risks associated with their loss (loss, compromise).</Paragraph>
            <Paragraph>12.4. The administrator is entitled to ensure the security of the Wallet and (or) to prevent its use for unauthorized or fraudulent purposes to perform a number of actions in accordance with applicable law, the Rules, including Wallet Blocking.</Paragraph>
          </Paragraph>
      </Section>

    <Header>13. VALIDITY AND TERMINATION OF OBLIGATIONS</Header>
        <Section>
          <Paragraph>
            <Paragraph>13.1. The Client may unilaterally refuse to comply with the terms of the Rules subject to the
              following conditions: - lack of unfulfilled obligations of the Client to the Administrator and (or) third parties interacting with the Client using the System and (or) its Services; - receipt by the Administrator of a duly issued notice on refusal to comply with the terms of the Rules sent to him by the Customer at least 30 (thirty) calendar days in advance.
            </Paragraph>
            <Paragraph>13.2. The Administrator has the right to refuse to fulfill the terms of the Rules in relation to a particular Client unilaterally with the mandatory written notification of the Client no later than three days in advance.</Paragraph>
            <Paragraph>13.3. From the date of such refusal, the acceptance of instructions for making transfers in favor of the Client is terminated, and all unpaid Fees immediately become payable. Any monetary obligations of the Parties arising prior to the date of such refusal shall be retained until their full performance.</Paragraph>
            <Paragraph>13.4. The administrator can withhold any funds of the Client until the latter fully repays his obligations and from these funds repays the payments due from the Client for his obligations.</Paragraph>
          </Paragraph>
      </Section>

    <Header>14. AMENDMENT OF THE RULES</Header>
        <Section>
          <Paragraph>
            <Paragraph>14.1. As necessary, the Administrator has the right to unilaterally make appropriate changes to the Rules.</Paragraph>
            <Paragraph>14.2. The amended version of the Rules is published in the open part of the Site.</Paragraph>
            <Paragraph>14.3. The use by the Client of the System after the introduction of the changes into action is understood as his unconditional consent to these changes.</Paragraph>
          </Paragraph>
        </Section>
    
    <Header>15. JURISDICTION AND APPLICABLE LAW</Header>
        <Section>
          <Paragraph>
            <Paragraph>15.1. All Services are provided in accordance with applicable law. If the Client’s use of the System
              and Services is contrary to the laws of his jurisdiction, the Administrator asks such Client not to use the Services. The client is fully responsible for compliance with the laws of his jurisdiction and for any possible losses or losses from non-compliance with this requirement.
            </Paragraph>
          </Paragraph>
    </Section>

    <Header>16. DISPUTE RESOLUTION</Header>
        <Section>
          <Paragraph>
            <Paragraph>16.1. All disputes and disagreements arising in the process of using the System on the basis of the Rules are resolved through negotiations.</Paragraph>
            <Paragraph>16.2. The basis for the start of negotiations is the sending by one Party of a written claim to the
              other Party. The claim is considered within thirty days from the moment of its receipt. If the dispute cannot be resolved in the complaint procedure within the specified time period, any of the Parties shall have the right to apply to the court at the location of the Administrator.
            </Paragraph>
          </Paragraph>
        </Section>

    <Header>17. FORCE MAJEURE</Header>
        <Section>
          <Paragraph>
            <Paragraph>17.1. The parties shall not be liable for any delays in the performance or non-fulfillment of obligations, any damage, including losses, as well as expenses associated with claims or claims of third parties that may arise as a result of force majeure circumstances. A Party with respect to which force
              majeure circumstances are in effect is obliged to notify the other Party no later than 10 days after the occurrence of these circumstances
            </Paragraph>
          </Paragraph>
        </Section>

    <Header>18. OTHER PROVISIONS</Header>
        <Section>
          <Paragraph>
            <Paragraph>18.1. The Client is not entitled to transfer to third parties his rights and obligations arising from the Rules without the prior written consent of the Administrator.</Paragraph>
            <Paragraph>18.2. If one or more of the provisions of the Rules is or becomes invalid due to a court decision or amendments to the applicable law, this does not entail the cancellation of the remaining provisions of the Rules.</Paragraph>
            <Paragraph>18.3. The administrator has the right to make any changes to the Rules aimed at bringing them into line with the new legislation.</Paragraph>
            <Paragraph>18.4. All appendices to the Rules are their integral part.</Paragraph>
          </Paragraph>
    </Section>

      <Header>
        <Box>APPENDIX I TO THE "GoldenBit Terms&Conditions".</Box>
        <Box mt="10px">Prohibited goods, works and services:</Box>
      </Header>
      <Section>
        <Paragraph>
          1. Weapons, ammunition and self-defense equipment, exact copies of
          firearms or cold steel.
        </Paragraph>
        <Paragraph>
          2. Narcotic and equivalent substances and plants, as well as their
          ingredients or any means for their production, as well as descriptions
          of methods for the manufacture of such substances.
        </Paragraph>
        <Paragraph>
          3. Medicines and medical products dispensed only by prescription, as
          well as raw materials or instructions for their production
        </Paragraph>
        <Paragraph>4. State awards or decals.</Paragraph>
        <Paragraph>
          5. Identity cards and government documents (including fake ones), as
          well as everything related to the creation or receipt of false
          identity cards or fake government documents.
        </Paragraph>
        <Paragraph>6. The uniform of state bodies.</Paragraph>
        <Paragraph>7. Specialty items related to police and law enforcement.</Paragraph>
        <Paragraph>8. Electronic equipment prohibited in this territory</Paragraph>
        <Paragraph>9. Devices for breaking or opening locks.</Paragraph>
        <Paragraph>
          10. Information containing personal data or data suitable for carrying
          out illegal activities (spam mailings, etc.).
        </Paragraph>
        <Paragraph>11. Information containing state, banking or commercial secrets.</Paragraph>
        <Paragraph>
          12. Intellectual Property, the sale of which violates the copyright
          and / or related rights of third parties, rights to trademarks or
          patents.
        </Paragraph>
        <Paragraph>
          13. Information that violates the privacy of privacy, infringes on the
          honor, dignity and business reputation of citizens and legal entities.
        </Paragraph>
        <Paragraph>
          14. Information transmitted exclusively virtually and not recorded on
          any material medium (ideas, methods, principles, etc.).
        </Paragraph>
        <Paragraph>15. Objects of archaeological heritage</Paragraph>
        <Paragraph>
          16. Items or services that violate the norms of public morality
          (including, but not limited to: child pornography, Nazi memorabilia,
          escort services, prostitution).
        </Paragraph>
        <Paragraph>17. Any equipment for organizing and conducting gambling.</Paragraph>
        <Paragraph>
          18. Any items with originally available but deleted serial numbers.
        </Paragraph>
        <Paragraph>19. Malicious software.</Paragraph>
        <Paragraph>
          20. Goods or services that directly or indirectly induce unlawful acts
          (propaganda of social, racial, religious or ethnic hatred;
          discrimination, violence, hatred, revenge, harassment, containing
          propaganda of terror or harm).
        </Paragraph>
        <Paragraph>21. Perishable goods</Paragraph>
        <Paragraph>22. Counterfeit goods.</Paragraph>
        <Paragraph>
          23. Items and equipment intended for use (including illegal transfer)
          of copyright objects without the permission of the authors (including,
          but not limited to: elements or software of electronic devices
          intended to remove copyright protection or regional restrictions)
        </Paragraph>
        <Paragraph>
          24. Dangerous goods (including, but not limited to: containing
          explosive, toxic, toxic, radioactive materials).
        </Paragraph>
        <Paragraph>
          25. Information on the manufacture of explosive, pyrotechnic,
          incendiary, etc. substances and devices.
        </Paragraph>
        <Paragraph>26. Human organs and remains</Paragraph>
        <Paragraph>27. Goods or services that do not have consumer value.</Paragraph>
        <Paragraph>
          28. Services of auctions or trading systems that allow you to conduct
          activities that exclude the transfer of labor and consumer goods that
          have consumer value.
        </Paragraph>
        <Paragraph>
          29. Financial or payment instruments whose accounting systems do not
          provide proper identification of the owner’s identity for the purpose
          of combating illegal trade, financial fraud, money laundering and
          legalization of illegally obtained funds.
        </Paragraph>
        <Paragraph>
          30. Asset management services and investment activities without
          appropriate licenses, as well as any intermediary activity that
          facilitates the transfer of funds between participants in transactions
          prohibited in this clause.
        </Paragraph>
        <Paragraph>
          31. Objects of history and art that make up the cultural values of a
          given country.
        </Paragraph>
        <Paragraph>
          32. Services for advertising (promotion) of goods, works and services
          referred to in paragraphs. 1-31 of this list.
        </Paragraph>
      </Section>
    </Section>
  );
};

export default UsersAgreement;
