import { FC, useCallback, useEffect, useState } from 'react';
import QRCodeReact from 'qrcode.react';
import Tabs from '../../components/Tabs';
import useAppSelector from '../../hooks/useAppSelector';
import {
  getActualAddress,
  selectActualAddress,
  WalletType,
} from '../../store/walletSlice';
import copyToClipboard from '../../helpers/copyToClipboard';
import useAppDispatch from '../../hooks/useAppDispatch';
import { AppDispatch } from '../../store';
import { Box, Typography, useTheme, TabsUnstyled } from '@mui/material';
import TextFieldForCopy from '../../components/TextFieldForCopy';
import { StyledTab, StyledTabsList } from './Actives.styled';
import Button from "@mui/material/Button";

interface ReplenishProps {
  selectedWallet: WalletType;
  clickFastBuy: () => void;
}

const Replenish: FC<ReplenishProps> = ({ selectedWallet, clickFastBuy }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const theme = useTheme();

  const actualAddress = useAppSelector(selectActualAddress);
  const [address, setAddress] = useState<string>('');
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    selectedWallet && dispatch(getActualAddress(selectedWallet.id));
  }, [selectedWallet]);

  useEffect(() => {
    setAddress('');
    actualAddress &&
      actualAddress.find((item) => {
        if (item.network === getNetworkItems(selectedWallet.currency_id)[0]) {
          setAddress(item.address);
        }
      });
  }, [actualAddress]);

  const onCopyAddress = useCallback((data: string) => {
    copyToClipboard(data);
  }, []);

  const getNetworkItems = (currency_id: string) => {
    switch (currency_id) {
      case 'USDT':
        return ['TRX', 'ETH'];
      case 'BTC':
        return ['BTC'];
      case 'ETH':
        return ['ETH'];
      case 'TRX':
        return ['TRX'];
      case 'TRRB':
        return ['TRX'];
      case 'TMC':
        return ['TRX'];
      default:
        return ['ETH', 'BTC', 'TRX'];
    }
  };

  const handleChange = (item: string) => {
    setAddress('');
    actualAddress &&
      actualAddress.find((re) => {
        if (re.network === item) {
          setAddress(re.address);
        }
      });
  };

  const networkItems = getNetworkItems(selectedWallet.currency_id);

  return !selectedWallet || !selectedWallet.isCoin ? (
    <Box my="20px" textAlign="center" fontSize="18px" color="#999999" flex="1">
      Функция ввода и вывода RUB находится в разработке.
    </Box>
  ) : (
    <Box display="flex">
      <Box
        sx={{
          flex: {
            md: '0.7',
          },
        }}
      >
        <Box
          color={theme.palette.secondary.dark}
          sx={{
            paddingTop: '14px',
            fontSize: '14px',
            lineHeight: '21px',
            mb: '20px',
          }}
        >
          Выберите сеть
        </Box>
        <Box
          sx={{
            margin: '10px 0 24px',
          }}
        >
          <TabsUnstyled
            value={tab}
            onChange={(
              event: React.SyntheticEvent,
              newValue: string | number
            ) => {
              const index = Number(newValue);

              setTab(index);
              handleChange(networkItems[index]);
            }}
          >
            <StyledTabsList>
              {networkItems.map((item) => (
                <StyledTab>{item === 'TRX' ? 'TRON' : item}</StyledTab>
              ))}
            </StyledTabsList>
          </TabsUnstyled>
        </Box>

        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '21px',
            mb: '10px',
          }}
        >
          Пожалуйста скопируйте адрес в Ваш кошелек, введите сумму, которую Вы
          хотите зачислить и подтвердите зачисление. Для оплаты из мобильного
          приложения отсканируйте QR код.
        </Typography>

        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '21px',
            mb: '24px',
            color: '#ff0000',
          }}
        >
          Обязательно убедитесь , что отправляйте на данный адрес кошелька
          только валюту «{selectedWallet.currency_id}».
          <br />В ином случае средства будут безвозвратно утеряны.
        </Typography>

        <Box
          sx={{
            display: {
              sm: 'block',
              md: 'none',
            },
          }}
        >
          {address ? (
            <QRCodeReact value={address} size={180} />
          ) : (
            <div> Ваш QR-код отсутствует</div>
          )}
        </Box>

        <div style={{ marginTop: '32px' }}>
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '21px',
              color: theme.palette.secondary.dark,
              marginBottom: '10px',
            }}
          >
            Адрес кошелька
          </Box>

          <TextFieldForCopy
            value={address}
            onClick={() => onCopyAddress(address)}
            noValueText='Адрес кошелька отсутствует'
          />

          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '21px',
              color: '#ff1616',

              marginTop: {
                sm: '20px',
                md: '28px',
              },
              marginBottom: {
                sm: '0px',
                md: '24px',
              },
            }}
          >
            Это адрес депозита в формате {selectedWallet.currency_id}{' '}
            {networkItems[tab] === 'ETH' ? 42 : 34} cимвола.
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          flex: '0.3',
          justifyContent: 'flex-end',
          marginLeft: ' 32px',
          alignItems: {
            sm: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            sm: 'column',
            md: 'row',
          },
          display: {
            sm: 'none',
            md: 'flex',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          alignItems="center"
        >
          {selectedWallet.name === 'USDT' &&
            <Button onClick={clickFastBuy}>Быстрая покупка USDT</Button>
          }
          {address ? (
            <QRCodeReact value={address} size={180} />
          ) : (
            <div> Ваш QR-код отсутствует</div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Replenish;
