import { AxiosResponse } from 'axios';
import { IFilter } from '../../store/otcSlice';
import {
  BalanceUpdateProps,
  CryptoOutProps,
  checkCryptoOutMixFixProps,
  HistoryType,
  FastBuyAmount,
  Transaction,
  WalletType,
} from '../../store/walletSlice';
import { httpClient } from '../httpClient';

export interface GetWalletsResponseValue {
  wallets: WalletType[];
}

export interface GetActualAddressResponseValue {
  address: string;
}

export interface GetHistoryResponseValue {
  walletHistory: HistoryType[];
}

export interface GetTransactionsRequestValues {
  direction?: string;
  status?: string;
  page: number;
  take: number;
  //filters?: IFilter[];
}

export interface GetTransactionsResponseValue {
  page: number;
  take: number;
  data: Transaction[];
  total: number;
}

export interface GetMyTransactionsProps {
  direction?: string;
  page: number;
  take: number;
}

export interface CryptoFeeProps {
  currencyId: string;
  networkId: string;
}

export interface GetCryptoOutFeeRequestValues {
  currencyId: string;
  networkId: string;
  volume: number;
}

export const walletService = {
  getWallets(): Promise<AxiosResponse<GetWalletsResponseValue>> {
    return httpClient.get('/api/v1/wallets');
  },

  getActualAddress(
    walletId: string
  ): Promise<AxiosResponse<GetActualAddressResponseValue>> {
    return httpClient.get('/api/v1/wallet/actual-address', {
      params: { walletId },
    });
  },

  getCurrencyList(): Promise<AxiosResponse> {
    return httpClient.get('/api/v1/currency/list');
  },
  getHistory(
    walletId: string
  ): Promise<AxiosResponse<GetHistoryResponseValue>> {
    return httpClient.get('/api/v1/wallet/history', { params: { walletId } });
  },

  getMyTransactions(props: GetMyTransactionsProps): Promise<AxiosResponse> {
    return httpClient.get('/api/v1/wallet/transactions/list/my', {
      params: props,
    });
  },

  getTransaction(id: string): Promise<AxiosResponse> {
    return httpClient.get('/api/v1/wallet/transactions/' + id);
  },

  postBalanceUp(props: BalanceUpdateProps): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/wallets/up', props);
  },

  postBalanceDown(props: BalanceUpdateProps): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/wallets/down', props);
  },

  postFastBuyAmount(props: FastBuyAmount): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/fiatgateway/request', props);
  },

  postPaymentCreate(props: FastBuyAmount): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/fiatgateway/create', props);
  },

  postPaymentConfirm(props: FastBuyAmount): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/fiatgateway/confirm', props);
  },

  createCryptoOut(props: CryptoOutProps): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/wallet/create-crypto-out', props);
  },
  checkCryptoOutMixFix(props: checkCryptoOutMixFixProps): Promise<AxiosResponse> {
    return httpClient.post('/api/v1/wallet/crypto-out/min-fix', props);
  },
  getSummary(currency: string): Promise<AxiosResponse> {
    return httpClient.get('/api/v1/wallets/summary', { params: { currency } });
  },
  getTransactionList(
    requestValues: GetTransactionsRequestValues
  ): Promise<AxiosResponse<GetTransactionsResponseValue>> {
    return httpClient.get('/api/v1/wallet/transactions/list', {
      params: requestValues,
    });
  },
  confirmTransaction(transactionId: string): Promise<AxiosResponse> {
    return httpClient.post(
      '/api/v1/wallet/transactions/confirm/' + transactionId,
      {}
    );
  },
  rejectTransaction(transactionId: string): Promise<AxiosResponse> {
    return httpClient.post(
      '/api/v1/wallet/transactions/reject/' + transactionId,
      {}
    );
  },

  getCryptoFee(requestValues: CryptoFeeProps) {
    return httpClient.post('/api/v1/wallet/crypto/fee', requestValues);
  },
  getOutFee(requestValues: GetCryptoOutFeeRequestValues) {
    return httpClient.post('/api/v1/wallet/crypto-out/fee', requestValues);
  },
};
