import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  styled,
  ButtonUnstyled,
  FormControl,
  TextField,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorPopup } from '../../components/ErrorPopup';
import { OverlayPopup } from '../../components/OverlayPopup';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import * as Yup from 'yup';
import { AppDispatch, RootState } from '../../store';
import usePopup from '../../hooks/usePopup';
import {
  getPasswordRecoveryLink,
  postNewPassword,
  setError,
} from '../../store/authSlice';
import { SuccessPopup } from '../../components/SuccessPopup';

interface PasswordRecoveryProps {
  onClose: () => void;
}

interface RecoveryPasswordFormValues {
  code: string;
  newPassword: string;
  userName?: string;
}

const formSchema = Yup.object().shape({
  code: Yup.string().required('Поле обязательное'),
  newPassword: Yup.string()
    .required('Поле обязательное')
    .min(8, 'Пароль должен быть не менее 8 символов')
    .matches(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
    .matches(/[a-z]/, 'Пароль должен содержать хотя бы одну строчную букву')
    .matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру'),
  confirmNewPassword: Yup.string()
    .required('Поле обязательное')
    .oneOf([Yup.ref('newPassword')], 'Пароли должны совпадать'),
});

export const PasswordRecovery: FC<PasswordRecoveryProps> = ({ onClose }) => {
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });
  const error = useAppSelector((state) => state.auth.error);
  const loading = useAppSelector((state) => state.auth.loading);

  const dispatch: AppDispatch = useAppDispatch();
  const { setPopup } = usePopup();
  const user = useAppSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(getPasswordRecoveryLink(user!!.login));
  }, []);

  useEffect(() => {
    if (error) {
      setPopup(
        <ErrorPopup
          onClose={() => {
            dispatch(setError(null));
            setPopup(null);
          }}
          errorMessage={error.message}
        />
      );
    }
  }, [error]);

  const onSubmit = useCallback(
    async ({ code, newPassword }: RecoveryPasswordFormValues) => {
      if (user) {
        const { login } = user;
        const response = await dispatch(
          postNewPassword({
            code,
            newPassword,
            login,
          })
        );

        if (response && response.payload) {
          setPopup(
            <SuccessPopup
              onClose={() => {
                setPopup(null);
              }}
              message="Пароль успешно изменён!"
            />
          );
        }
      }
    },
    [user]
  );

  return (
    <OverlayPopup onClose={onClose}>
      <Box p="24px">
        <Box
          fontWeight="bold"
          mb="20px"
          sx={{
            textAlign: {
              sm: 'center',
              md: 'left',
            },
            fontSize: {
              sm: '18px',
              md: '36px',
            },
          }}
        >
          На Вашу почту был отправлен код
        </Box>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box mt="10px">
            <FormControl fullWidth>
              <TextField
                label="Код"
                variant="standard"
                {...form.register('code')}
                helperText={
                  form.formState.errors.code &&
                  form.formState.errors.code.message
                    ? form.formState.errors.code.message
                    : null
                }
              />
            </FormControl>
          </Box>
          <Box mt="10px">
            <FormControl fullWidth>
              <TextField
                label="Новый пароль"
                type="password"
                variant="standard"
                {...form.register('newPassword')}
                helperText={
                  form.formState.errors.newPassword &&
                  form.formState.errors.newPassword.message
                    ? form.formState.errors.newPassword.message
                    : null
                }
              />
            </FormControl>
          </Box>
          <Box mt="10px">
            <FormControl fullWidth>
              <TextField
                label="Повторите новый пароль"
                type="password"
                variant="standard"
                {...form.register('confirmNewPassword')}
                helperText={
                  form.formState.errors.confirmNewPassword &&
                  form.formState.errors.confirmNewPassword.message
                    ? form.formState.errors.confirmNewPassword.message
                    : null
                }
              />
            </FormControl>
          </Box>
          <Box mt="20px">
            <StyledSubmitButton type="submit">
              {loading ? 'Подождите...' : 'Отправить'}
            </StyledSubmitButton>
          </Box>
        </form>
      </Box>
    </OverlayPopup>
  );
};

const StyledSubmitButton = styled(ButtonUnstyled)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 36px;
  border: 0;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
`;
