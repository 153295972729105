import React, { useCallback, useEffect, useMemo } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterFormSchema } from '../../constants/validators';
import {
  FormControl,
  Checkbox,
  Box,
  styled,
  ButtonUnstyled,
  Typography,
  TextField,
} from '@mui/material';
import {
  register,
  selectIsLoading,
  selectError,
  selectSuccessRegistered,
  setError,
  setRegisteredFailed,
} from '../../store/authSlice';
import useAppSelector from '../../hooks/useAppSelector';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
//import { ErrorPopup } from '../../components/ErrorPopup';
import { SuccessPopup } from '../../components/SuccessPopup';
import usePopup from '../../hooks/usePopup';
import { useQuery } from '../../hooks/useQuery';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  referralCode?: string;
}

const RegistrationForm = () => {
  const history = useHistory();
  const { setPopup } = usePopup();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const successRegistered = useAppSelector(selectSuccessRegistered);
  const [checked, setChecked] = React.useState<boolean>(false);
  const error = useAppSelector(selectError);
  const agreementIsChecked = () => {
    setChecked((prev) => !prev);
  };

  const query = useQuery();
  const referral = query.get('referral');

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(RegisterFormSchema),
  });
  useEffect(() => {
    dispatch(setError(null));
  }, []);

  useEffect(() => {
    if (successRegistered) {
      setPopup(
        <SuccessPopup onClose={() => setPopup(null)}>
          <Box>
            <b>Регистрация прошла успешно!</b>
            <br />
            <b>Ссылка активации отправлена на вашу почту.</b>
          </Box>
        </SuccessPopup>
      );
      dispatch(setRegisteredFailed());
    }
  }, [successRegistered]);

  const onSubmit = useCallback(
    ({ email, password, referralCode }: FormValues) => {
      dispatch(setError(null))
      localStorage.setItem(
        'register_credentials',
        JSON.stringify({
          email,
          password,
        })
      );

      if (referral) {
        dispatch(
          register({
            email,
            password,
            referralCode: referral,
          })
        );
      } else {
        dispatch(
          register({
            email,
            password,
          })
        );
      }
    },
    [referral]
  );

  const { formState } = form;

  const errors = useMemo(() => {
    let list = [];
    if (error) list.push(error.message);

    const formMessages = Object.keys(formState.errors).map(
      (k) => formState.errors[k].message
    );

    return formMessages.concat(list);
  }, [formState, error]);

  useEffect(() => {
    return () => {
      dispatch(setError(null))
    }
  }, []);

  return (
    <FormProvider {...form}>
      <Box
        fontSize="36px"
        fontWeight="bold"
        sx={{
          textAlign: {
            xs: 'center',
            md: 'left',
          },
        }}
        mb="10px"
      >
        Регистрация
      </Box>

      {errors.length ? (
        <ErrorContainer>
          <Typography
            variant="h6"
            style={{
              fontSize: '16px',
            }}
          >
            Действие не удалось из-за ошибок:
          </Typography>
          <ul>
            {errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="standard"
            label="Email"
            {...form.register('email')}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="standard"
            label="Пароль"
            {...form.register('password')}
            type="password"
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="standard"
            label="Повторите пароль"
            {...form.register('confirmPassword')}
            type="password"
          />
        </FormControl>
        <Box color="#d9d9d9" fontSize="12px" mt="20px">
          Пароль должен содержать хотя бы одну цифру, одну прописную букву и
          одну строчную букву и быть не менее 8 символов.
        </Box>
        <FormControl margin="dense">
          <Box display="flex">
            <Box>
              <Checkbox checked={checked} onChange={agreementIsChecked} />
            </Box>
            <Box ml="10px" fontSize="12px">
              Регистрируясь на сайте, Вы принимаете
              <Link to="/agreements">Правила пользования сайтом </Link>,
              соглашаетесь с условиями{' '}
              <Link to="/agreements?tab=2">Пользовательского соглашения </Link>,{' '}
              <Link to="/agreements?tab=3">общих положений AML</Link> и{' '}
              <Link to="/agreements">Политикой конфиденциальности </Link>.
            </Box>
          </Box>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <StyledSubmitButton type="submit" disabled={!checked || loading}>
            {loading ? 'Подождите...' : 'Зарегистрироваться'}
          </StyledSubmitButton>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <StyledLink textAlign="center" onClick={() => history.goBack()}>
            Отмена
          </StyledLink>
        </FormControl>
      </form>
    </FormProvider>
  );
};

const ErrorContainer = styled(Box)`
  border: 1px solid #f5c6cb;
  border-radius: 3px;
  background-color: #f8d7da;
  color: #dc3545;
  padding: 24px;
`;

const StyledSubmitButton = styled(ButtonUnstyled)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  border-radius: 36px;
  width: 100%;
  border: 0;
  padding: 15px 0;
`;

const StyledLink = styled(Box)`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
`;

export default RegistrationForm;
