import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createCryptoOut,
  CryptoOutProps,
  checkCryptoOutMixFix,
  getCryptoFee,
  getOutFee,
  selectCryptoFee,
  setLastError,
  WalletType,
  getMyTransactions,
  getWallets,
} from '../../store/walletSlice';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  styled,
  TextField,
  useTheme,
  TabsUnstyled,
  Typography,
  ButtonUnstyled,
} from '@mui/material';

import useAppSelector from '../../hooks/useAppSelector';
import { getCalculatedFee } from '../../store/feeSlice';
import * as Yup from 'yup';
import usePopup from '../../hooks/usePopup';
import { ErrorPopup } from '../../components/ErrorPopup';
import { SuccessPopup } from '../../components/SuccessPopup';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AppDispatch, RootState } from '../../store';
import { StyledTab, StyledTabsList } from './Actives.styled';
import { AlertPopup } from '../../components/AlertPopup';

const schema = Yup.object().shape({
  address: Yup.string().required('Поле обязательное'),
});

interface WithdrawProps {
  selectedWallet: WalletType;
}

const Withdraw: FC<WithdrawProps> = ({ selectedWallet }) => {
  const [selectedPercent, setSelectedPercent] = useState(0);
  const [networkId, setNetworkId] = useState('');
  const [error, setError] = useState(false);
  const [outMixFix, setOutMixFix] = useState(0);
  const outFee = useAppSelector((state) => state.wallet.outFee);
  const outCurrencyId = useAppSelector((state) => state.wallet.outCurrencyId);
  const blockchainOutFee = useAppSelector(
    (state) => state.wallet.blockchainOutFee
  );
  const blockchainOutCurrencyId = useAppSelector(
    (state) => state.wallet.blockchainOutCurrencyId
  );

  const [tab, setTab] = useState<number>(0);
  const dispatch: AppDispatch = useAppDispatch();
  const theme = useTheme();
  const { setPopup } = usePopup();
  const [volume, setVolume] = useState<number>(0);
  const lastError = useAppSelector(
    (state: RootState) => state.wallet.lastError
  );

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const networks = useMemo(() => {
    switch (selectedWallet.currency_id) {
      case 'USDT':
        return ['TRX', 'ETH'];
      case 'BTC':
        return ['BTC'];
      case 'ETH':
        return ['ETH'];
      case 'TRX':
        return ['TRX'];
      case 'TRRB':
        return ['TRX'];
      case 'TMC':
        return ['TRX'];
      default:
        return ['ETH', 'BTC', 'TRX'];
    }
  }, [selectedWallet]);

  useEffect(() => {
    setTab(0);
    form.setValue('total', '0');
  }, []);

  useEffect(() => {
    setNetworkId(networks[0]);

    form.reset();

    form.setValue('walletId', selectedWallet.id);
  }, [selectedWallet]);

  useEffect(() => {
    if (selectedWallet.isCoin && networkId) {
      dispatch(
        getOutFee({
          currencyId: selectedWallet.currency_id,
          networkId,
          volume,
        })
      );
    }
  }, [networkId, volume]);

  useEffect(() => {
    //if (outFee) {
    form.setValue('fee', outFee);
    form.setValue('blockchainFee', blockchainOutFee);
    //}
  }, [outFee, blockchainOutFee]);

  useEffect(() => {
    if (selectedWallet) {
      setVolume(selectedPercent * selectedWallet.service_balance * 0.01);
    }
  }, [selectedPercent]);

  useEffect(() => {
    if (volume !== undefined) {
      form.setValue('volume', volume);
      form.setValue('total', volume);
    }
  }, [volume]);

  useEffect(() => {
    if (lastError) {
      setPopup(
        <ErrorPopup
          onClose={() => {
            setPopup(null);
            dispatch(setLastError(null));
          }}
          errorMessage={lastError}
        />
      );
    }
  }, [lastError]);

  const onSubmit = useCallback(
    async (data: CryptoOutProps) => {
      const volume = Number(form.getValues('volume'));
      if (volume > 0 && volume <= selectedWallet.service_balance) {
        const minimum = await dispatch(
          checkCryptoOutMixFix({
            currencyId: selectedWallet.currency_id,
            networkId,
          })
        );
        setOutMixFix(minimum.payload);
        if (minimum.payload <= volume) {
          setPopup(
            <AlertPopup
              title="Подтверждение"
              closeable={true}
              onClose={() => setPopup(null)}
              positiveButton="Подтвердить"
              onPositiveButtonClick={async () => {
                form.reset();
                setVolume(0);
                setError(false);

                const response = await dispatch(
                  createCryptoOut({
                    walletId: data.walletId,
                    volume: data.volume,
                    fee: data.fee,
                    address: data.address,
                    networkId,
                  })
                );

                if (response.payload) {
                  dispatch(
                    getMyTransactions({
                      direction: 'out',
                      page: 1,
                      take: 20,
                    })
                  );

                  dispatch(getWallets())

                  setPopup(
                    <SuccessPopup
                      onClose={() => setPopup(null)}
                      message="Операция прошла успешно!"
                    />
                  );
                }
              }}
            >
              <>
                Вы уверены что хотите вывести на этот адрес кошелька "
                {data.address}" "{volume}" "{selectedWallet.name}"
                <br />
                Внимание, если кошелек не принимает эту валюту , ваши средства
                будут утрачены.
              </>
            </AlertPopup>
          );
        } else {
          setError(true);
        }
      }
    },
    [networkId]
  );

  return !selectedWallet.isCoin ? (
    <Box my="20px" textAlign="center" fontSize="18px" color="#999999" flex="1">
      Функция ввода и вывода RUB находится в разработке.
    </Box>
  ) : outCurrencyId && blockchainOutCurrencyId ? (
    <Box mb="20px">
      <Box
        color={theme.palette.secondary.dark}
        sx={{
          paddingTop: '14px',
          lineHeight: '21px',
          fontSize: '14px',
        }}
      >
        Выберите сеть
      </Box>
      <Box mt="20px">
        <TabsUnstyled
          value={tab}
          onChange={(
            event: React.SyntheticEvent,
            newValue: string | number
          ) => {
            form.reset();

            const index = Number(newValue);

            setTab(index);
            setNetworkId(networks[index]);
          }}
        >
          <StyledTabsList>
            {networks.map((item) => (
              <StyledTab>{item === 'TRX' ? 'TRON' : item}</StyledTab>
            ))}
          </StyledTabsList>
        </TabsUnstyled>
      </Box>

      <Box mt="24px" fontSize="14px" lineHeight="21px">
        Пожалуйста, введите адрес кошелька, укажите сумму к выводу и нажмите на
        кнопку «Отправить».
        <br />
        Заявка на вывод средств будет подтверждена автоматически в зависимости
        от состояния сети выбранной Вами валюты.
      </Box>

      <Box
        mt="24px"
        display="flex"
        justifyContent="space-between"
        sx={{
          borderTop: {
            md: '1px solid #D9D9D9',
          },
          paddingTop: {
            md: '24px',
          },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            sm: 'center',
          },
        }}
      >
        <Box fontSize="16px" fontWeight="bold">
          Вывести {selectedWallet.currency_id}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="10px"
          sx={{
            justifyContent: {
              xs: 'space-between',
            },
            paddingTop: {
              xs: '12px',
              sm: '0px',
            },
          }}
        >
          <Box color="#999999">Доступно к выводу:</Box>
          <Box fontWeight="bold">
            {selectedWallet.service_balance} {selectedWallet.currency_id}
          </Box>
        </Box>
      </Box>
      <Box mt="30px">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <input type="hidden" {...form.register('walletId')} />
            <input type="hidden" {...form.register('volume')} />

            <Box>
              <FormLabel>Адрес вывода</FormLabel>
              <TextField
                variant="standard"
                fullWidth
                {...form.register('address')}
              />
            </Box>

            <Box mt="20px">
              <FormLabel>Сумма вывода</FormLabel>
              <Box display="flex" alignItems="center" gap="10px">
                <Box flex="1">
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="input"
                    customInput={OutSummTextField}
                    thousandSeparator=" "
                    allowLeadingZeros={true}
                    allowNegative={false}
                    style={{
                      width: '100%',
                      border: 0,
                      borderBottom: '2px solid ' + theme.palette.primary.main,
                      boxSizing: 'border-box',
                      userSelect: 'none',
                    }}
                    value={volume}
                    onValueChange={(values: NumberFormatValues) => {
                      setVolume(values.floatValue ? values.floatValue : 0);
                    }}
                    isAllowed={(values: NumberFormatValues) => {
                      const { floatValue } = values;
                      return (
                        !floatValue ||
                        (floatValue >= 0 &&
                          floatValue <= selectedWallet.service_balance)
                      );
                    }}
                    disabled={selectedWallet.service_balance === 0}
                  />
                </Box>
                <Box flex="1" display="flex" alignItems="center" gap="10px">
                  {selectedWallet.service_balance > 0 &&
                    Array.from(Array(4).keys())
                      .map((i) => i * 25 + 25)
                      .map((p) => (
                        <PercentButton
                          style={{
                            backgroundColor:
                              p === selectedPercent
                                ? theme.palette.primary.main
                                : '',
                          }}
                          onClick={() => setSelectedPercent(p === selectedPercent ? 0 : p)}
                        >
                          {p}%
                        </PercentButton>
                      ))}
                </Box>
              </Box>
              {error ? (
                <Box mt="10px" fontSize="12px" color="red">
                  Минимальная сумма вывода {outMixFix}{' '}
                  {selectedWallet.currency_id}
                </Box>
              ) : (
                <></>
              )}
            </Box>

            <Box mt="20px">
              <Box display="flex" alignItems="center" gap="40px">
                <Box>
                  <FormLabel>Комиссия биржи</FormLabel>
                  <Box display="flex" alignItems="center" gap="10px">
                    <StyledTextField
                      disabled
                      variant="standard"
                      {...form.register('fee')}
                    />
                    <Box fontWeight="bold">{outCurrencyId}</Box>
                  </Box>
                </Box>
                <Box>
                  <FormLabel>Комиссия сети</FormLabel>
                  <Box display="flex" alignItems="center" gap="10px">
                    <StyledTextField
                      disabled
                      variant="standard"
                      {...form.register('blockchainFee')}
                    />
                    <Box fontWeight="bold">{blockchainOutCurrencyId}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box mt="20px">
              <FormLabel>Сумма выплаты</FormLabel>
              <Box>
                <StyledTextField
                  disabled
                  variant="standard"
                  fullWidth
                  {...form.register('total')}
                />
              </Box>
            </Box>

            <Box textAlign="right" mt="20px">
              <StyledButton
                type="submit"
                disabled={!form.formState.isValid || volume === 0}
              >
                Отправить
              </StyledButton>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  ) : (
    <LoadingLayout>
      <CircularProgress />
    </LoadingLayout>
  );
};

const LoadingLayout = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
`;

const PercentButton = styled(Box)`
  background-color: #999999;
  color: #ffffff;
  font-size: 12px;
  border-radius: 36px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
`;

const OutSummTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '4px 0 5px',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root.Mui-disabled:before': {
    borderBottomStyle: 'solid',
  },
}));

const StyledButton = styled(ButtonUnstyled)`
  background: ${(props) => props.theme.palette.primary.main};
  color: #ffffff;
  border: 0;
  border-radius: 32px;
  padding: 2px 12px;
  height: 32px;
  text-transform: none;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  &:disabled {
    background: rgba(245, 245, 245, 1);
    color: #000000;
  }
`;

export default Withdraw;
