import React from 'react';
import styles from './style.module.scss';
import { Header, Section, Paragraph } from '../styledComponents';

const AmlRules = () => {
  return <>
    <Header>1. Introduction</Header>
        <Section>
        <Paragraph>
            Goldenbit UAB (GOLDENBIT) is a financial institution with an active authorization to provide its services. GOLDENBIT follows and implements all relevant AML/CTF and Sanctions compliance regulatory requirements that are currently applicable in the Republic of Lithuania. This statement serves as a representation of our internal policies and procedures and provides overall understanding of AML/CTF and Sanctions compliance approaches currently in use in GOLDENBIT.
        </Paragraph>
    </Section>
        
    <Header>2. Risk-based approach</Header>
        <Section>
        <Paragraph>
            International AML/CTF standards demand risk-based approach (RBA) to be implemented for every financial institution. RBA helps the institution to allocate its resources, setup an appropriate internal controls system, and enables it to focus on higher risk areas. 
        </Paragraph>
        <Paragraph>
            In GOLDENBIT we estimate all possible money laundering, terrorism financing and sanctions violation risks. At the same time, we implement our own AML/CTF and Sanctions compliance internal controls, that are most appropriate for our type of business. 
        </Paragraph>
    </Section>
    
    <Header>3. Due Diligence and Know Your Customer  </Header>
        <Section>
        <Paragraph>
            In GOLDENBIT we utilize Know Your Customer (KYC) principles in our day-to-day operations. It means that we know every single person with whom we intend to establish business relationship, as well as among those we already cooperate with. 
        </Paragraph>
        <Paragraph>
            GOLDENBIT applies Customer Due Diligence (CDD) measures on every Customer before establishing business relationship, on a regular basis in course of the business relationship and on an ad hoc basis in accordance with its internal policies and procedures. 
        </Paragraph>
        <Paragraph>
            GOLDENBIT will apply Enhanced Due Diligence (EDD) measures every time it faces higher AML/CTF risks. In its operations GOLDENBIT utilizes AML/CTF risk scoring approach, thus we are able to timely identify areas of higher AML/CTF risk and apply mitigation measures without a delay. 
        </Paragraph>
      </Section>
      
    <Header>4. Sanctions  </Header>
        <Section>
        <Paragraph>
            GOLDENBIT has a policy of zero tolerance against sanctioned persons or persons related to them. We cooperate with the best AML software providers that enable us to apply automated ongoing screening of our Customers against all mandatory sanctions list. We ensure that every person that we have or intend to have business relationship with is properly screened against sanctions lists and we are dully notified if any potential risk of sanctions violation emerges. In case of increased risk of sanctions violation, we act in accordance with our internal policies and procedures, that are based on the regulatory requirements of the Republic of Lithuania. 
        </Paragraph>
      </Section>
      
    <Header>5. Training  </Header>
        <Section>
        <Paragraph>
            GOLDENBIT relentlessly raises awareness of its staff in AML/CTF and Sanctions compliance related matters. We ensure that all our employees are aware of the requirements and their own obligations related to combating money laundering, terrorism financing and sanctions violation. Thus, we ensure that all employees receive a proper training on the commencement of their duties, as well as more enhanced ongoing training in course of their employment. 
        </Paragraph>
    </Section>
    </>
};

export default AmlRules;
