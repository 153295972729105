import React from 'react';
import { Popover } from '@mui/material';

type anchorElType = HTMLElement | null;

interface OverlayPopoverProps {
  children: JSX.Element;
  open: boolean;
  anchorEl: anchorElType;
  setAnchorEl: React.Dispatch<React.SetStateAction<anchorElType>>;
}

const OverlayPopover = ({ children, open, anchorEl, setAnchorEl }: OverlayPopoverProps) => {
  return (
    <Popover
        disableScrollLock
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      transitionDuration={0}
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', }}
      PaperProps={{
        sx: {
          minWidth: anchorEl?.offsetWidth,
          borderRadius: '24px',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.06)',
          color: '#000000',
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -12,
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
}

export default OverlayPopover;