import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuList,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  TextField,
  useTheme,
  CircularProgress,
  Typography,
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
  TextFieldProps,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { PagingTable } from '../../components/PagingTable';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { getMarketsOtc, selectMarkets } from '../../store/marketSlice';
import {
  getAdvertisementList,
  getPaymentMethods,
  IFilter,
  selectAdvertisements,
  selectPaymentMethods,
  Currency
} from '../../store/otcSlice';
import { selectUser } from '../../store/userSlice';
import DownArrowIcon from '../../icons/DownArrowIcon.svg';
import checkIsNotNull from '../../helpers/checkIsNotNull';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { AppDispatch, RootState } from '../../store';
import OverlayPopover from '../../components/OverlayPopover';

interface AdvertisementProps {
  side: 'ask' | 'bid';
}

/**
 * Объявления P2P
 * @author Sasha Broslavskiy
 */
const Advertisements = ({ side }: AdvertisementProps) => {
  /** диспетчер состояния */
  const dispatch: AppDispatch = useAppDispatch();

  /** методы оплаты */
  const paymentMethods = useAppSelector(selectPaymentMethods);

  /** список объявлений с сервера */
  const advertisements = useAppSelector(selectAdvertisements);

  /** текущий пользователь */
  const user = useAppSelector(selectUser);

  /** флаг для фильтрации верфицированных пользователей в списке объявлений */
  const [onlyVerifiedUsers, setOnlyVerifiedUsers] = useState<boolean>(false);

  /** текущая тема */
  const theme = useTheme();
  const [paymentMethod, setPaymentMethod] = useState<string | null>();
  const markets = useAppSelector(selectMarkets);
  const [currencyList, setCurrencyList] = useState<string[]>([]);
  const [mainCurrency, setMainCurrency] = useState<string | undefined>();
  const [paidCurrency, setPaidCurrency] = useState<string | undefined>();
  const [anchorCurrencyFilterEl, setAnchorCurrencyFilterEl] = useState<HTMLElement | null>(null);
  const [anchorPaymentFilterEl, setAnchorPaymentFilterEl] = useState<HTMLElement | null>(null);
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);
  const [currencyTab, setCurrencyTab] = useState<number>(0);
  const totalAdvertisements = useAppSelector(
    (state: RootState) => state.otc.totalAdvertisements
  );

  const isAskSide = side === 'ask';

  const advertisementsOrderByVolumeMax = useMemo(
    () =>
      [...advertisements].sort((a, b) =>
        Number(a.volumeMax) > Number(b.volumeMax) ? -1 : 0
      ),
    [advertisements]
  );

  const ITEMS_PER_PAGE = 20;
  const currencyListOrder = [Currency.BTC, Currency.ETH, Currency.TRX, Currency.USDT];
  const [fiatCurrencyList, setFiatCurrencyList] = useState<string[]>([]);
  const [cryptCurrencyList, setCryptCurrencyList] =  useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    console.log(`get payment methods`);
    dispatch(
      getPaymentMethods({
        page: 1,
        take: 20,
      })
    );
    console.log(`get markets`);
    dispatch(getMarketsOtc());
    console.log(`get advertisement list`);

    onChangePage(1);
  }, []);

  useEffect(() => {
    if (markets) {
      setFiatCurrencyList(
        Array.from(new Set(markets
          .filter(market => !market.paidCurrency.isCoin)
          .map(market => market.paidCurrency.id
          )
        ))
      )

      setCryptCurrencyList(
        Array.from(new Set(markets
          .filter(market => market.paidCurrency.isCoin)
          .map(market => market.paidCurrency.id
          )
        ))
      )

      const currencyListArray: Currency[] = markets.map(
        (market) => market.mainCurrencyId as Currency
      );

      const currencyListSet = new Set();

      setCurrencyList(
        currencyListArray
          .filter((item) => {
            return currencyListSet.has(item)
              ? false
              : currencyListSet.add(item);
          })
          .sort(
            (firstCurrency: Currency, secondCurrency: Currency): number => {
              return currencyListOrder.indexOf(firstCurrency) - currencyListOrder.indexOf(secondCurrency)
            }
          )
      );
    }
  }, [markets]);

  useEffect(() => {
    setMainCurrency(
      currencyTab === 0 ? undefined : currencyList[currencyTab - 1]
    );
  }, [currencyTab]);

  const onNameClick = useCallback((userId: string) => {
    history.push(
      generatePath('/advertisement/seller-profile/:userId', {
        userId,
      })
    );
  }, []);

  const onChangePaymentMethod = useCallback(
    (value) => setPaymentMethod(value === 'any' ? undefined : value),
    []
  );

  const onChangePage = useCallback(
    (page: number) => {
      let filters: IFilter[] = [
        {
          field: 'side',
          operator: '=',
          value: side,
        },
      ];

      if (min !== null) {
        filters.push({
          field: 'volume',
          operator: '>=',
          value: min,
        });
      }

      if (max !== null) {
        filters.push({
          field: 'volumeMax',
          operator: '<=',
          value: max,
        });
      }

      if (paymentMethod) {
        filters.push({
          field: 'paymentMethodId',
          operator: 'in',
          value: [
            paymentMethod
          ]
        })
      }

      if (onlyVerifiedUsers) {
        filters.push({
          field: 'verified',
          operator: '=',
          value: onlyVerifiedUsers
        })
      }

      if (mainCurrency) {
        filters.push({
          field: 'mainCurrency',
          operator: '=',
          value: mainCurrency
        })
      }

      if (paidCurrency) {
        filters.push({
          field: 'paidCurrency',
          operator: '=',
          value: paidCurrency
        })
      }
      

      dispatch(
        getAdvertisementList({
          page,
          take: ITEMS_PER_PAGE,
          filters,
        })
      );
    },
    [side, min, max, paymentMethod, onlyVerifiedUsers, mainCurrency, paidCurrency]
  );

  useEffect(() => onChangePage(1), [side, min, max, paymentMethod, onlyVerifiedUsers, mainCurrency, paidCurrency]);

  const anyPaymentMethod = {
    id: 'any',
    name: 'Метод оплаты (любой)',
  };

  const isCurrentPaidCurrency = (item: string): boolean => !!paidCurrency && paidCurrency === item;
  return (
    <>
      {paymentMethods &&
      markets &&
      markets.length ? (
        <>
          <Box
            overflow="auto"
            sx={{
              paddingBottom: {
                sm: '10px',
                lg: '0px',
              },
            }}
          >
            <TabsUnstyled defaultValue={0} value={currencyTab}>
              <StyledTabsList>
                {['Все', ...currencyList].map((currency, index) => (
                  <StyledTab onClick={(event) => setCurrencyTab(index)}>
                    {currency}
                  </StyledTab>
                ))}
              </StyledTabsList>
            </TabsUnstyled>
          </Box>

          <Box
            display="flex"
            gap="25px"
            mt="24px"
            sx={{
              alignItems: {
                sm: 'stretch',
                md: 'flex-end',
              },
              flexDirection: {
                sm: 'column',
                md: 'row',
              },
            }}
          >
            <Box
              flex="1"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                setAnchorCurrencyFilterEl(e.currentTarget)}
            >
              <CustomTextField
                label="Оплата"
                value={
                  paidCurrency
                    ? fiatCurrencyList.includes(paidCurrency)
                      ? `${paidCurrency}(Фиат)`
                      : `${paidCurrency}(Крипта)`
                    : ''
                }
              />
            </Box>
            <OverlayPopover
              open={Boolean(anchorCurrencyFilterEl)}
              anchorEl={anchorCurrencyFilterEl}
              setAnchorEl={setAnchorCurrencyFilterEl}
            >
              <Box p="15px">
              <Box mb="10px">Оплата за Фиат:</Box>
                <Box display="flex" gap="10px">
                  {fiatCurrencyList.map((item) => (
                    <Box
                      style={{
                        border:
                          isCurrentPaidCurrency(item)
                            ? `2px solid ${theme.palette.primary.main}`
                            : `2px solid #d9d9d9`,
                        borderRadius: '36px',
                        padding: '7px 16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPaidCurrency(isCurrentPaidCurrency(item) ? undefined : item);
                        setAnchorCurrencyFilterEl(null);
                      }}
                    >
                      {item}
                    </Box>
                  ))}
                </Box>
                <Box mt="10px" mb="10px">
                  Оплата за Крипту:
                </Box>
                <Box display="flex" flexWrap="wrap" gap="10px" rowGap="10px">
                  {currencyList
                    .filter((item) => cryptCurrencyList.includes(item))
                    .map((item) => (
                      <Box
                        key={item}
                        style={{
                          border:
                            isCurrentPaidCurrency(item)
                              ? `2px solid ${theme.palette.primary.main}`
                              : `2px solid #d9d9d9`,
                          borderRadius: '36px',
                          padding: '7px 16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setPaidCurrency(isCurrentPaidCurrency(item) ? undefined : item);
                          setAnchorCurrencyFilterEl(null);
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                </Box>
              </Box>
            </OverlayPopover>

            <Box
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="10px"
            >
              <Box>от</Box>
              <NumberFormat
                thousandsGroupStyle="thousand"
                allowedDecimalSeparators={['.', ',']}
                displayType="input"
                customInput={SummIntervalTextField}
                thousandSeparator=" "
                allowLeadingZeros={true}
                value={min}
                style={{
                  width: '100%',
                  border: 0,
                  borderBottom: '2px solid ' + theme.palette.primary.main,
                  boxSizing: 'border-box',
                }}
                onValueChange={(values: NumberFormatValues) => {
                  const { floatValue } = values;

                  setMin(floatValue ?? null);
                }}
              />

              <Box>до</Box>

              <NumberFormat
                thousandsGroupStyle="thousand"
                allowedDecimalSeparators={['.', ',']}
                displayType="input"
                customInput={SummIntervalTextField}
                thousandSeparator=" "
                allowLeadingZeros={true}
                value={max}
                style={{
                  width: '100%',
                  border: 0,
                  borderBottom: '2px solid ' + theme.palette.primary.main,
                  boxSizing: 'border-box',
                }}
                onValueChange={(values: NumberFormatValues) => {
                  const { floatValue } = values;

                  setMax(floatValue ?? null);
                }}
                allowNegative={false}
              />
            </Box>

            <Box
              flex="1"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                setAnchorPaymentFilterEl(e.currentTarget)}
            >
              <CustomTextField
                value={
                  paymentMethods.find(
                    item => item.id === paymentMethod
                  )?.name || anyPaymentMethod.name
                }
              />
            </Box>
            <OverlayPopover
              open={Boolean(anchorPaymentFilterEl)}
              anchorEl={anchorPaymentFilterEl}
              setAnchorEl={setAnchorPaymentFilterEl}
            >
              <MenuList>
                {[anyPaymentMethod, ...paymentMethods].map((item) => (
                  <MenuItem
                    key={item.id}
                    selected={item.id === (paymentMethod || anyPaymentMethod.id)}
                    onClick={() => {
                      onChangePaymentMethod(item.id);
                      setAnchorPaymentFilterEl(null);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </OverlayPopover>
          </Box>

          <Box mt="24px">
            <FormControlLabel
              control={<Checkbox defaultChecked={onlyVerifiedUsers} />}
              label="Только верифицированные продавцы"
              onChange={(event, checked) => setOnlyVerifiedUsers(checked)}
            />
          </Box>

          <Divider mt="24px" mb="24px" mx="-24px" />

          <Typography fontWeight="600" fontSize="16px" lineHeight="24px">
            Предложения
          </Typography>
          <Box mt="20px">
            <PagingTable
              items={advertisements}
              itemsPerPage={ITEMS_PER_PAGE}
              total={totalAdvertisements}
              heads={[
                'Продавец',
                'Метод оплаты',
                'Ставка',
                'Сумма',
                'Скорость ответа',
                '',
              ]}
              loading={false}
              onRow={(item, index) => (
                <StyledTableRow key={item.id}>
                  <TableCell onClick={() => onNameClick(item.user.userId)}>
                    <Box display="flex">
                      <Box fontSize="14px" fontWeight="500">
                        {item.user.name}
                      </Box>
                      <Box></Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.paymentMethods.length > 0
                      ? item.paymentMethods
                          .map((paymentMethod) => paymentMethod.name)
                          .join(', ')
                      : 'Любой'}
                  </TableCell>
                  <TableCell>
                    {(item.factor * 100).toFixed(1)}% (
                    {item.factor < 0
                      ? isAskSide
                        ? 'доплата вам'
                        : 'вы доплачиваете'
                      : isAskSide
                      ? 'вы доплачиваете'
                      : 'доплата вам'}
                    )
                  </TableCell>
                  <TableCell>
                    {`${item.volume} — ${item.volumeMax} ${
                      markets
                        ? markets.find((market) => market.id === item.marketId)
                            ?.mainCurrencyId
                        : null
                    }`}
                  </TableCell>
                  <TableCell>{item.user.answerRate}</TableCell>
                  <TableCell align="right">
                    <StyledButton
                      onClick={() =>
                        history.push(
                          generatePath('/advertisement/:id', {
                            id: item.id,
                          })
                        )
                      }
                      disabled={user?.id === item.user?.userId}
                    >
                      {isAskSide ? 'Купить' : 'Продать'}
                    </StyledButton>
                  </TableCell>
                </StyledTableRow>
              )}
              onHeadRowCell={(item) => (
                <StyledTableHeadCell>{item}</StyledTableHeadCell>
              )}
              maxHeight="700px"
              onChangePage={(page: number) => onChangePage(page)}
            />
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="60px"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
`;

const StyledTableHeadCell = styled(TableCell)`
  background-color: #ffffff;
  font-size: 12px;
  line-height: 100%;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #ffffff;
  border-radius: 32px;
  padding: 2px 12px;
  height: 32px;
  text-transform: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  &:disabled {
    background-color: rgba(245, 245, 245, 1);
    color: #000000;
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const StyledTabsList = styled(TabsListUnstyled)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTab = styled(TabUnstyled)`
  display: flex;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 32px;
  border: 0;
  padding: 10px 18px;
  cursor: pointer;
  border: 2px solid transparent;
  &.${tabUnstyledClasses.selected} {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: #ffffff;
    font-weight: 500;
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const SummIntervalTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '4px 0 5px',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const CustomTextField = (props: TextFieldProps) => (
  <TextField
    {...props}
    variant="standard"
    fullWidth
    InputProps={{
      sx: { cursor: 'pointer' },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <img src={DownArrowIcon} alt="" />
          </IconButton>
        </InputAdornment>
      ),
    }}
    inputProps={{
      disabled: true,
      style: {
        cursor: 'pointer',
        textOverflow: 'ellipsis',
      }
    }}
  />
);

export { Advertisements };
