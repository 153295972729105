import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {WalletType,} from '../../store/walletSlice';
import {
  Box,
  Button,
  FormLabel,
  styled, TabsListUnstyled,
  TextField,
} from '@mui/material';
import {
  TabsUnstyled
} from '@mui/base';
import {
  postFastBuyAmount,
  postPaymentConfirm,
  postPaymentCreate,
  FastBuyAmount
} from '../../store/walletSlice';

import usePopup from '../../hooks/usePopup';
import { StyledTab } from './Actives.styled';
import Popup from "../../components/Popup";
import TextFieldForCopy from "../../components/TextFieldForCopy";
import copyToClipboard from "../../helpers/copyToClipboard";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import useAppDispatch from '../../hooks/useAppDispatch';
import { clear } from 'console';

interface FastBuyProps {
  selectedWallet: WalletType;
}


const FastBuy: FC<FastBuyProps> = ({ selectedWallet }) => {
  const dispatch = useAppDispatch();
  const { setPopup } = usePopup();
  const [tab, setTab] = useState<number>(0);
  const [commission, setCommission] = useState<FastBuyAmount[]>([]);
  const [makeBank, setMakeBank] = useState<number>(0);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      isValid
    }
  } = useForm({
    mode: "all",
    criteriaMode: "all"
  });

  const handleChange = (
      event: React.SyntheticEvent,
      newValue: string | number
  ) => {
    event.preventDefault();
    setTab(Number(newValue));
  };

  const searchBank = async () => {
    const response = await dispatch(
      postFastBuyAmount({
        amount: makeBank
      })
    );
    setCommission(response.payload)
  }

  const onChangeBuying = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value)
    setMakeBank(value)
  }

  const checkStatus = (data: FastBuyAmount) => {
    // сделать проверку статуса оплаты
    return true
  }

  const clearSearch = () => {
    setCommission([])
    setMakeBank(0)
  }

  const onSubmitAccept = useCallback(async(data: FastBuyAmount) => {
    clearSearch()
    const response = await dispatch(
      postPaymentConfirm({
        invoice_id: data.invoice_id
      })
    );
    {
      response && response.payload ? (
        setPopup(
          <Popup
            onClose={() => { }}
            closeable={false}
            fixedWidth={false}
          >
            <Box sx={{
              maxWidth: "483px",
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}>
              <Box
                pl="10px"
                sx={{
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                Пожалуйста, ожидайте
              </Box>
              <Box
                sx={{
                  fontWeight: '500',
                  fontSize: '18px',
                  display: "flex",
                  gap: "15px",
                  flexDirection: "column"
                }}
              >
                <Box pl="10px">
                  <span style={{ fontWeight: "bold" }}> USDT</span> будут зачислены на Ваш счет в течении 20 минут.
                </Box>
              </Box>
              <Box sx={{
                display: "flex",
                width: "160px"
              }}>
                {/* <StyledCancelButton onClick={checkStatus(data)}>Проверить статус</StyledCancelButton> */}
                <StyledCancelButton onClick={() => setPopup(null)}>Понятно</StyledCancelButton>
              </Box>
            </Box>
          </Popup>
        )
      ) : (
      setPopup(
        <Popup
          onClose={() => { }}
          closeable={false}
          fixedWidth={false}
        >
          <Box sx={{
            maxWidth: "483px",
            display: "flex",
            flexDirection: "column",
            gap: "20px"
          }}>
            <Box
              pl="10px"
              sx={{
                fontWeight: '700',
                fontSize: '24px',
              }}
            >
              Что-то пошло не так, попробуйте позже
            </Box>
            <Box sx={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
              flexWrap: "wrap"
            }}>
              <StyledCancelButton onClick={() => setPopup(null)}>Отменить</StyledCancelButton>
            </Box>
          </Box>
        </Popup>
      )
    )}
  }, [commission])

  const onSubmit = useCallback(async (data) => {
    const response = await dispatch(
      postPaymentCreate({
        ad_id: commission[tab].ad_id,
        amount: makeBank,
        payment_info: data.buyer
      })
    );

    {
      response && response.payload ? (
        setPopup(
          <Popup
            onClose={() => { }}
            closeable={false}
            fixedWidth={false}
          >
            <Box sx={{
              maxWidth: "483px",
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}>
              <Box
                pl="10px"
                sx={{
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                Покупка  USDT
              </Box>
              <Box
                sx={{
                  fontWeight: '500',
                  fontSize: '18px',
                  display: "flex",
                  gap: "15px",
                  flexDirection: "column"
                }}
              >
                <Box pl="10px">
                   {/*Переведите <span style={{fontWeight: "bold"}}>{commission.sellingValue + commission.commissionValue} RUB</span> на карту <span style={{fontWeight: "bold"}}>{banks[tab]}</span>:*/}
                   Переведите <span style={{fontWeight: "bold"}}>{data.buyingValue} RUB</span> на карту <span style={{fontWeight: "bold"}}>{response.payload.bank_name}</span>:
                </Box>
                <TextFieldForCopy
                  value={response.payload.card_number}
                  noValueText={response.payload.card_number}
                  onClick={() => copyToClipboard(response.payload.card_number)}
                />
              </Box>
              <Box sx={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                flexWrap: "wrap"
              }}>
                <StyledAcceptButton onClick={() => onSubmitAccept(response.payload)}>Перевод подтверждаю</StyledAcceptButton>
                <StyledCancelButton onClick={() => setPopup(null)}>Отменить</StyledCancelButton>
              </Box>
              <Box pl="10px">
                <p style={{ fontWeight: "bold" }}>
                  Правила быстрой покупки USDT:
                </p>
                <p>
                  <p>
                    У Вас есть 20 минут на совершение перевода средств и нажатие кнопку «Перевод подтверждаю».
                    Если Вы совершили перевод средств и не нажали на кнопку "Перевод подтверждаю" или нажали на кнопку "Отмена",
                    то необходимо об этом сообщить в поддержку в Telegram: <span style={{ fontWeight: "bold" }}>@golden_bit_support</span>
                  </p>
                  <p>
                    Если Вы совершили перевод, но не успели уложиться в 20 минут - не нужно создавать повторные сделки,
                    необходимо сообщить об этом в поддержку и прикрепить чеки, подтверждающие оплату.
                  </p>
                  <p>
                    Сумма перевода должна быть в точности такой же, как в указанной заявке, до копеек. Отправка денежных средств осуществляется внутрибанковским платежом.
                    Перевод необходимо осуществить из банка, который был выбран в заявке.
                  </p>
                  <p>
                    Перевод средств с карт Qiwi, Yoomoney и аналогичных - запрещен. Такие платежи будут передаваться на арбитраж и
                    зачисление по ним не гарантировано.
                  </p>
                  <p>
                    При переводе средств категорически запрещено указывать любые комментарии. При отправке платежа с комментарием
                    возможно взымание штрафных санкций (до 50% от отправленной суммы).
                  </p>
                </p>
              </Box>
            </Box>
          </Popup>
        )
      ) : (
        setPopup(
          <Popup
            onClose={() => { }}
            closeable={false}
            fixedWidth={false}
          >
            <Box sx={{
              maxWidth: "483px",
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}>
              <Box
                pl="10px"
                sx={{
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                Что-то пошло не так, попробуйте позже
              </Box>
              <Box sx={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                flexWrap: "wrap"
              }}>
                <StyledCancelButton onClick={() => setPopup(null)}>Отменить</StyledCancelButton>
              </Box>
            </Box>
          </Popup>
        )
      )
    }
  }, [commission, tab])
  return (
    <Box mb="20px">
      <Box mt="30px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <StyledFormLabel>Покупаете, RUB:</StyledFormLabel>
            <TextField
              type="number"
              variant="standard"
              fullWidth
              {...register('buyingValue', {
                onChange: onChangeBuying,
                required: true,
                valueAsNumber: true,
                min: 50
              })}
            />
          </Box>
          {commission && commission.length === 0 && (
            <>
              <Box my="10px" color="#FF1616" flex="1" lineHeight="32px">
                <div>Минимальная сумма покупки: 1000.00 RUB.</div>
              </Box>
              <Box mt="10px">
                <StyledButton
                  onClick={searchBank}
                  disabled={makeBank < 1000}
                >
                Найти банк
                </StyledButton>
              </Box>
            </>
          )}
          {commission && commission.length > 0 && (
            <>
              <Box mt="24px" gap="15px" flexDirection="column">
                <StyledFormLabel>Банк покупателя:</StyledFormLabel>
                <Box
                  mt="10px"
                >
                <TabsUnstyled
                  value={tab}
                  onChange={handleChange}
                >
                  <StyledTabsList>
                    {commission.map((item) => (
                      <StyledTab key={item.bank_name}>{item.bank_name}</StyledTab>
                    ))}
                  </StyledTabsList>
                </TabsUnstyled>
                </Box>
              </Box>

            <Box mt="24px">
              <StyledFormLabel>ФИО покупателя:</StyledFormLabel>
              <TextField
                type="text"
                variant="standard"
                fullWidth
                {...register('buyer', {
                  required: 'Данное поле обязательное!',
                  pattern: {
                    value: /^[A-Za-zА-яа-яё\s]+$/iu,
                    message: 'Данное поле может содержать только кириллицу или латинские буквы!'
                  }
                }
                )}
              />
              {errors.buyer && (<span style={{ marginTop: "10px", color: "red" }}>{errors.buyer.message}</span>)}
            </Box>

            <Box my="20px" color="#FF1616" flex="1" lineHeight="32px">
              <div>У Вас будет 20 минут на перевод средств после начала сделки.</div>
            </Box>


            <Box mt="20px">
              <>
                <StyledButton
                  type="submit"
                  disabled={!isValid && makeBank < 1000}
                >
                Начать сделку
                </StyledButton>
                <StyledButton
                  onClick={clearSearch}
                  disabled={!isValid && makeBank < 1000}
                  style={{
                    marginLeft: 10
                  }}
                >
                Отмена
                </StyledButton>
              </>
            </Box>
            </>
          )}
        </form>
      </Box>
    </Box>
  )
};

const LoadingLayout = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
`;

const StyledFormLabel = styled(FormLabel)`
  color: #8F8982;
`

const PercentButton = styled(Box)`
  background-color: #999999;
  color: #ffffff;
  font-size: 12px;
  border-radius: 36px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
`;

const StyledAcceptButton = styled(ButtonUnstyled)`
  flex: 2;
  background: #CBA977;
  border-radius: 100px;
  color: #fff;
  border: 0;
  padding: 16px 32px;
  &:hover {
    background: #dfbf91;
  }
`;

const StyledCancelButton = styled(ButtonUnstyled)`
  flex: 1;
  border: 1px solid #CBA977;
  border-radius: 100px;
  color: #CBA977;
  padding: 16px 32px;
  background: transparent;
  &:hover {
    background: #fff3e2;
  }
`;

const StyledButton = styled(Button)`
  text-transform: inherit;
  border-radius: 100px;
  background: #CBA977;
  color: white;
  height: 40px;
  width: 146px;
  &:hover {
    background: #CBA977;
  };
  &:disabled {
    color: white;
    background: rgba(203, 168, 119, 0.2)
  }
`

const OutSummTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '4px 0 5px',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

}));

const StyledTextField= styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root.Mui-disabled:before": {
    borderBottomStyle: 'solid'
  }
}));

export const StyledTabsList = styled(TabsListUnstyled)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export default FastBuy;
